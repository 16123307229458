export const ambassadorsPath = '/ambassadors'
export const forgotPasswordPath = '/forgot-password'
export const howToPath = '/get-started'
export const learnMorePath = '/learn-more'
export const logoutPath = '/logout'
export const registerPath = '/register'
export const resetPasswordPath = '/reset-password/:userId/:token'
export const expiredPasswordPath = '/expired-password'
export const resourcesPath = '/collateral'
export const rootPath = '/'
export const signInPath = '/sign-in'
export const staffTrainingPath = '/staff-training'
export const trainingPath = '/training'
export const welcomePath = '/welcome'
export const rolloutPath = '/partner'
export const contactPath = '/contact'
