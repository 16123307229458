import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'

import Router from '../../components/Router'
import Form from './Form'
import Thanks from './Thanks'

export const thanksPath = 'thanks'

const Register: FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Form default />
      <Thanks path={thanksPath} />
    </Router>
  )
}

export default Register
