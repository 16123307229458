import React, { FC, Suspense } from 'react'
import theme from './theme'
import apolloClient from './apolloClient'
import { ApolloProvider } from '@apollo/react-hooks'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Routes from './Routes'
import GlobalStyles from './GlobalStyles'
import Loading from './components/Loading'
import { CurrentUserProvider } from './context/currentUser'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'

const App: FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <GlobalStyles />
            <Suspense fallback={<Loading />}>
              <CurrentUserProvider>
                <Routes />
              </CurrentUserProvider>
            </Suspense>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  )
}

export default App
