import React, { FC } from 'react'
import { Asset } from 'contentful'
import MarkdownTypography from './MarkdownTypography'
import Link from './Link'
import colors from '../theme/colors'
import { ICalloutSection } from '../types/generated/contentful'
import { makeStyles } from '@mui/styles'
import Grid, { GridSize } from '@mui/material/Grid'
import { Typography } from '@mui/material'

interface StyleProps {
  backgroundImg?: Asset
  purple: boolean
}

const useStyles = makeStyles<unknown, StyleProps>({
  calloutSection: {
    minHeight: 496,
  },
  calloutSectionInner: ({ backgroundImg, purple }) => ({
    backgroundImage: backgroundImg
      ? `url(${backgroundImg?.fields.file.url})`
      : '',
    backgroundColor: purple ? colors.purple.primary : colors.blue[400],
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'initial',
    backgroundClip: 'initial',
    backgroundSize: 'cover',
    mixBlendMode: 'multiply',
    height: '100%',
    padding: 32,
    width: '100%',
  }),
  text: {
    color: colors.white.primary,
  },
  margin: {
    marginTop: -24,
  },
})

interface CalloutSectionProps {
  entry: ICalloutSection
  itemSize?: GridSize
  purple?: boolean
}

const CalloutSection: FC<CalloutSectionProps> = ({
  entry,
  itemSize = 4,
  purple = false,
  ...props
}) => {
  const classes = useStyles({ backgroundImg: entry.fields.image, purple })

  return (
    <Grid
      className={classes.calloutSection}
      item
      xs={12}
      sm={itemSize}
      {...props}
    >
      <Link underline="none" href={entry.fields.url}>
        <div className={classes.calloutSectionInner}>
          <MarkdownTypography
            source={entry.fields.text}
            renderers={{
              h1: props => (
                <Typography
                  className={classes.text}
                  variant="h4"
                  component="h3"
                  {...props}
                />
              ),
              p: props => (
                <Typography
                  className={`${classes.text} ${classes.margin}`}
                  variant="body1"
                  {...props}
                />
              ),
            }}
          />
        </div>
      </Link>
    </Grid>
  )
}

export default CalloutSection
