import React, { FC } from 'react'
import queryString from 'query-string'
import { RouteComponentProps } from '@reach/router'
import useContentfulEntry from '../../contentful/useContentfulEntry'
import { replace, isArray, first } from 'lodash'
import { MarkdownPageTitleSection } from '../MarkdownTypography'
import { accountsId } from '../../contentful/ids'
import { IAccountsFields } from '../../types/generated/contentful'
import { Container } from '@mui/material'

const Thanks: FC<RouteComponentProps> = ({ location = { search: '' } }) => {
  const entry = useContentfulEntry<IAccountsFields>(accountsId)

  if (!entry) return null
  const {
    fields: { registerThanksMessage },
  } = entry

  const { email } = queryString.parse(location.search)
  const normalizedEmail = isArray(email) ? first(email) : email || ''
  const message = replace(
    registerThanksMessage,
    '{{email}}',
    normalizedEmail || ''
  )

  return (
    <Container>
      <header>
        <MarkdownPageTitleSection source={message} />
      </header>
    </Container>
  )
}

export default Thanks
