import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { contactPageId } from '../contentful/ids'
import { IContactPageFields } from '../types/generated/contentful'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { makeStyles } from '@mui/styles'
import {
  MarkdownPageTitleSection,
  MarkdownPageBodySection,
} from './MarkdownTypography'
import { ContactForm } from './ContactForm'
import { Container } from '@mui/material'

const useStyles = makeStyles({
  body: {
    marginBottom: 32,
  },
})

const Contact: FC<RouteComponentProps> = () => {
  const entry = useContentfulEntry<IContactPageFields>(contactPageId)
  const classes = useStyles()

  if (!entry) return null

  const {
    fields: { title, body },
  } = entry

  return (
    <Container>
      <MarkdownPageTitleSection source={title} />
      <MarkdownPageBodySection source={body} className={classes.body} />
      <ContactForm formType="contact" />
    </Container>
  )
}

export default Contact
