import React, { FC } from 'react'
import {
  IProductSection,
  IProductSectionFields,
} from '../types/generated/contentful'
import { MarkdownPageBodySection } from './MarkdownTypography'
import useContentfulEntry from '../contentful/useContentfulEntry'
import Product from './Product'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'

interface ProductSectionProps {
  entry: IProductSection
}

const useStyles = makeStyles({
  productSection: {
    marginTop: 24,
    marginBottom: 64,
  },
})

const ProductSection: FC<ProductSectionProps> = ({ entry }) => {
  const classes = useStyles()
  const loadedEntry = useContentfulEntry<IProductSectionFields>(entry.sys.id)

  if (!loadedEntry) return null
  const {
    fields: { description, products },
  } = loadedEntry

  return (
    <Grid container className={classes.productSection} spacing={5}>
      <Grid item xs={12}>
        {description && <MarkdownPageBodySection source={description} />}
      </Grid>
      {products.map(product => (
        <Product key={product.sys.id} entry={product} />
      ))}
    </Grid>
  )
}

export default ProductSection
