import React, { FC, ReactNode } from 'react'
import { Link as MUILink } from '@mui/material'
import { Link as RouterLink } from '@reach/router'

const isExternalLink = (url: string): boolean => !!url.match(/^(https?:)?\/\//)

type Props = {
  children: ReactNode
}

interface LinkProps extends Props {
  to?: string
  href?: string
  underline?: 'none' | 'hover' | 'always' | undefined
}

const Link: FC<LinkProps> = ({ to, href = '', ...props }) => {
  return !to && isExternalLink(href) ? (
    <MUILink underline='hover' target="_blank" rel="noopener noreferrer" href={href} {...props} />
  ) : (
    <MUILink underline='hover' component={RouterLink} to={to || href} {...props} />
  )
}

export default Link
