import React, { FC } from 'react'
import { Field, getIn, FieldProps } from 'formik'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect
} from '@mui/material'

interface SelectProps {
  id: string
  name: string
  label: string
  children: React.ReactNode
  className?: string
  fullWidth?: boolean
}

interface SelectInputProps extends SelectProps, FieldProps {}

const SelectInput: FC<SelectInputProps> = ({
  id,
  label,
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  fullWidth,
  children,
  className,
}) => {
  const error = getIn(errors, name)
  const showError = getIn(touched, name) && !!error

  return (
    <FormControl
      error={showError}
      fullWidth={fullWidth}
      variant="filled"
      className={className}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <MuiSelect
        native
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{ name }}
      >
        {children}
      </MuiSelect>
      {showError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

const Select: FC<SelectProps> = props => (
  <Field component={SelectInput} {...props} />
)

export default Select
