import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import React, { FC, useState } from 'react'
import TextField from './TextField'

interface PasswordTextFieldProps {
  name: string
  label: string
  variant?: string
  validatePassword?: (password: string) => void
  isValidPassword?: boolean
  helperText?: string
}

const PasswordTextField: FC<PasswordTextFieldProps> = ({
  name,
  label,
  variant,
  validatePassword,
  isValidPassword,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      name={name}
      label={label}
      validatePassword={validatePassword}
      isValidPassword={isValidPassword}
      helperText={helperText}
      white={false}
      fullWidth
      type={showPassword ? 'text' : 'password'}
      variant={variant}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PasswordTextField
