import React, { FC } from 'react'
import { IProduct } from '../types/generated/contentful'
import MarkdownTypography from './MarkdownTypography'
import colors from '../theme/colors'
import { boldFontFamily } from '../theme'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'

interface ProductProps {
  entry: IProduct
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainer: {
    paddingBottom: '100%',
    width: '100%',
    marginBottom: 12,
  },
  description: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    '& > p + p': {
      marginTop: 24,
    },
    '& > ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      fontSize: '1rem',
    },
    '& a': {
      textDecoration: 'underline',
      fontFamily: boldFontFamily,
      fontWeight: 'bold',
      color: colors.black.medium,
    },
  },
  descriptionHeader: {
    color: colors.blue.primary,
    margin: 0,
  },
})

const Product: FC<ProductProps> = ({ entry }) => {
  const classes = useStyles()

  const {
    fields: { description, image },
  } = entry

  return (
    <Grid container item xs={12} spacing={5}>
      <Grid item xs={12} sm={6}>
        <div
          className={classes.imageContainer}
          style={{
            background: `${colors.blue[100]} url(${image.fields.file.url}) center center no-repeat`,
            backgroundSize: 'contain',
          }}
          role="img"
          aria-label={image.fields.title}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MarkdownTypography
          className={classes.description}
          source={description}
          renderers={{
            h1: props => (
              <Typography
                variant="h6"
                component="h3"
                className={classes.descriptionHeader}
                {...props}
              />
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default Product
