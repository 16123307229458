import React, { FC } from 'react'
import { Field, getIn } from 'formik'
import { kebabCase } from 'lodash'
import { makeStyles } from '@mui/styles'
import colors from '../theme/colors'
import { TextField as MUITextField } from '@mui/material'

const useInputLabelStyles = makeStyles({
  root: {
    color: colors.white.primary,
  },
  focused: {
    color: `${colors.white.primary} !important`,
  },
})

const useFieldToTextFieldProps = ({
  inputProps,
  field,
  form,
  disabled = false,
  label,
  onChangeSideEffect,
  validatePassword,
  isValidPassword,
  white,
  InputProps,
  ...props
}: any) => {
  const { name, onChange: fieldOnChange } = field
  const { touched, errors, isSubmitting } = form
  let fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError

  const onChange = onChangeSideEffect
    ? (...args: any) => {
        onChangeSideEffect(...args)
        fieldOnChange(...args)
      }
    : (val: any) => {
        fieldOnChange(val)
        validatePassword && validatePassword(val.target.value)
      }

  const inputLabelClasses = useInputLabelStyles()
  const InputLabelProps = white ? { classes: inputLabelClasses } : {}

  if (typeof isValidPassword !== 'undefined') {
    fieldError = ''
    if (isValidPassword) {
      fieldError = props.helperText
    }
  } else {
    // No need to show error message until user action completed
    if (!showError) {
      fieldError = ''
    }
  }

  return {
    ...props,
    ...field,
    onChange,
    error: showError,
    helperText: fieldError,
    disabled: isSubmitting || disabled,
    label,
    inputProps: {
      'data-testid': `${kebabCase(name)}-input`,
      'aria-label': label,
      ...inputProps,
    },
    InputProps,
    InputLabelProps,
  }
}

const TextInput: FC = props => (
  <MUITextField {...useFieldToTextFieldProps(props)} />
)

const TextField: any = (props: Object) => {
  return <Field component={TextInput} {...props} />
}

export default TextField
