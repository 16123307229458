import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { makeStyles } from '@mui/styles'
import { CssBaseline } from '@mui/material'

const useStyles = makeStyles({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
    },
    '#root': {
      height: '100%',
    },
  },
})

const GlobalStyles: React.FC = () => {
  useStyles()
  return <CssBaseline />
}

export default GlobalStyles
