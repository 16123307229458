import { Asset } from 'contentful'
import Video from 'react-player'
import Lightbox from './Lightbox'
import colors from '../theme/colors'
import Button from './Button'
import MarkdownTypography from './MarkdownTypography'
import { makeStyles } from '@mui/styles'
import {
  Container,
  Grid,
  Hidden,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useState } from 'react'
import React from 'react'
import Slider from 'react-slick'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  button: {
    backgroundColor: '#fff',
    color: colors.blue.primary,
    marginTop: 32,
    minWidth: 344,
    padding: '20px 8px',
    [breakpoints.down('xs')]: {
      alignSelf: 'center',
      minWidth: 100,
      width: '100%',
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: colors.blue.primary,
    },
  },
  carouselContainer: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  hero: {
    display: 'flex',
    margin: 0,
    width: '100%',
    maxWidth: 1280,
    minHeight: 524,
    paddingTop: 100,
    paddingBottom: 100,
    position: 'relative',
    [breakpoints.up('md')]: {
      padding: '100px 64px',
    },
  },
  heroContainer: {
    zIndex: 2,
  },
  heroText: {
    color: colors.white.primary,
  },
  video: {
    '&:focus': {
      outline: 'none',
    },
  },
}))

interface StyleProps {
  backgroundImage: Asset | null
}

const useBackgroundStyles = makeStyles<Theme, StyleProps>(
  ({ breakpoints }) => ({
    heroBackground: ({ backgroundImage }) => ({
      backgroundImage: backgroundImage
        ? `url(${backgroundImage.fields.file.url})`
        : '',
      backgroundColor: colors.blue.primary,
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'initial',
      backgroundClip: 'initial',
      backgroundSize: 'cover',
      height: '100%',
      minHeight: 524,
      mixBlendMode: 'multiply',
      width: '100%',
      [breakpoints.up('lg')]: {
        backgroundSize: 'cover',
      },
    }),
  })
)

interface HeroBackgroundProps {
  backgroundImage: Asset
}

const HeroBackground: FC<HeroBackgroundProps> = ({ backgroundImage }) => {
  const classes = useBackgroundStyles({ backgroundImage })
  return <div className={classes.heroBackground} role="img" aria-label="" />
}

const sliderOptions = {
  adaptiveHeight: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  className: '',
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1000,
}

interface HeroProps {
  backgroundImages: Asset[]
  subtitle: string
  title: string
  video?: Asset
  videoCallToAction?: string
}

const Hero: FC<HeroProps> = ({
  backgroundImages,
  subtitle,
  title,
  video,
  videoCallToAction,
}) => {
  const classes = useStyles()
  const [playVideo, setPlayVideo] = useState(false)
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {video && (
        <Lightbox open={playVideo} onClose={() => setPlayVideo(false)}>
          <div
            style={{
              height: 'auto',
              width: smDown ? '100%' : '80%',
              position: 'relative',
            }}
          >
            <Video
              url={video.fields.file.url}
              className={classes.video}
              playing={playVideo}
              controls
              width="100%"
              height="auto"
            />
          </div>
        </Lightbox>
      )}
      <div className={classes.hero}>
        <div className={classes.carouselContainer}>
          <Hidden smUp>
            <HeroBackground backgroundImage={backgroundImages[0]} />
          </Hidden>
          <Hidden only="xs">
            <Slider {...sliderOptions}>
              {backgroundImages.map(image => {
                return (
                  <HeroBackground key={image.sys.id} backgroundImage={image} />
                )
              })}
            </Slider>
          </Hidden>
        </div>
        <Container className={classes.heroContainer}>
          <Grid container>
            <Grid item xs={6}>
              <MarkdownTypography
                renderers={{
                  h1: props => (
                    <Typography
                      className={classes.heroText}
                      component="h1"
                      variant="h3"
                      {...props}
                    />
                  ),
                }}
                source={title}
              />
              <Typography
                className={classes.heroText}
                variant="h5"
                component="p"
              >
                {subtitle}
              </Typography>
            </Grid>
            {video && (
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={() => setPlayVideo(true)}
                >
                  {videoCallToAction}
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Hero
