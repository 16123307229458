"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_PARENT_EMAIL = exports.AGREE_TO_PARTICIPATE_ECE_COURSE = exports.CREATE_USER_ROLE = exports.CHANGE_ROLE = exports.UPDATE_USER_ROLE = exports.NOTIFY_PARTNER_ACTIVITY = exports.GET_INTERVENTION_BY_TMWID = exports.SAVE_ROLE_QUESTION_ANSWERS = exports.SAVE_EDUCATOR_QUESTION_ANSWERS = exports.DECLINE_SURVEY_BY_TYPE = exports.UPDATE_DEMOGRAPHIC_DATA = exports.UPDATE_PREFERRED_LANGUAGE = exports.UPDATE_PARTNER_ASSOCIATION = exports.SUBMIT_CONTACT_FORM = exports.SIGN_UP_PARTNER = exports.SHARE_CONTENT = exports.RESET_PASSWORD_MUTATION = exports.SEND_RESET_PASSWORD_URL = exports.AGREE_TO_DATA_COLLECTION = exports.IDENTIFY_INTERVENTION_PARENT = exports.ADD_INTERVENTION_EVENTS = exports.ADD_PARENT_EVENT = exports.SAVE_SURVEY_RESULTS_BY_TYPE = exports.SUBMIT_ENGAGEMENT_SURVEY = exports.UPDATE_INTERVENTION_ROOM = exports.TRANSFER_INTERVENTION_TO_CURRENT_USER = exports.ARCHIVE_COMPLETE = exports.UPDATE_INTERVENTION_LAST_ACTIVITY = exports.CREATE_INTERVENTION = exports.TRANSFER_INTERVENTIONS = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragments_1 = require("./fragments");
exports.TRANSFER_INTERVENTIONS = (0, graphql_tag_1.default) `
  mutation TransferInterventionsToCurrentUser($fids: String!) {
    transferFacilitatedInterventionsToCurrentUser(fids: $fids) {
      id
      facilitatorId
    }
  }
`;
exports.CREATE_INTERVENTION = (0, graphql_tag_1.default) `
  mutation CreateIntervention(
    $interventionSeriesId: Int!
    $roomName: String
    $status: FacilitatedInterventionStatus
    $parentDateOfBirth: String
    $childDateOfBirth: String
    $deviceId: String
    $events: [CreateInterventionEvent]
  ) {
    createIntervention(
      interventionSeriesId: $interventionSeriesId
      roomName: $roomName
      status: $status
      parentDateOfBirth: $parentDateOfBirth
      childDateOfBirth: $childDateOfBirth
      deviceId: $deviceId
      events: $events
    ) {
      ...FacilitatedIntervention
    }
  }
  ${fragments_1.facilitatedInterventionFragment}
`;
exports.UPDATE_INTERVENTION_LAST_ACTIVITY = (0, graphql_tag_1.default) `
  mutation UpdateFacilitatedInterventionLastUserActivity(
    $fiid: Int!
    $active: Boolean!
  ) {
    updateFacilitatedInterventionLastUserActivity(id: $fiid, active: $active) {
      id
      timeOfLastUserActivity
    }
  }
`;
exports.ARCHIVE_COMPLETE = (0, graphql_tag_1.default) `
  mutation ArchiveComplete {
    archiveCompletedFacilitatedInterventions {
      id
      status
      isArchived
    }
  }
`;
exports.TRANSFER_INTERVENTION_TO_CURRENT_USER = (0, graphql_tag_1.default) `
  mutation TransferFacilitatedInterventionsToCurrentUser($fids: String!) {
    transferFacilitatedInterventionsToCurrentUser(fids: $fids) {
      id
      facilitatorId
    }
  }
`;
exports.UPDATE_INTERVENTION_ROOM = (0, graphql_tag_1.default) `
  mutation UpdateFacilitatedInterventionRoom($id: Int!, $roomName: String!) {
    updateFacilitatedInterventionRoom(id: $id, roomName: $roomName) {
      id
      roomName
    }
  }
`;
exports.SUBMIT_ENGAGEMENT_SURVEY = (0, graphql_tag_1.default) `
  mutation SubmitInterventionEngagementSurvey($interventionId: Int!) {
    submitFacilitatedInterventionEngagementSurvey(
      interventionId: $interventionId
    ) {
      id
    }
  }
`;
exports.SAVE_SURVEY_RESULTS_BY_TYPE = (0, graphql_tag_1.default) `
  mutation SaveSurveyResultsByType(
    $results: [SurveyResultInput!]!
    $surveyId: String!
    $surveyType: String!
    $isLessonSurvey: Boolean!
  ) {
    saveSurveyResultsByType(
      results: $results
      surveyId: $surveyId
      surveyType: $surveyType
      isLessonSurvey: $isLessonSurvey
    ) {
      id
      surveyId
      surveyType
      tmwVariable
      value
    }
  }
`;
exports.ADD_PARENT_EVENT = (0, graphql_tag_1.default) `
  mutation AddParentEvent($eventType: String!, $data: String, $courseId: Int) {
    addParentEvent(eventType: $eventType, data: $data,courseId: $courseId) {
      id
    }
  }
`;
exports.ADD_INTERVENTION_EVENTS = (0, graphql_tag_1.default) `
  mutation AddInterventionEvents($events: [InterventionEventRequest!]!) {
    addInterventionEvents(events: $events) {
      id
    }
  }
`;
exports.IDENTIFY_INTERVENTION_PARENT = (0, graphql_tag_1.default) `
  mutation IdentifyInterventionParent(
    $interventionId: Int!
    $parentFirstName: String!
    $parentLastName: String!
    $parentDateOfBirth: String!
    $childDateOfBirth: String!
  ) {
    identifyInterventionParent(
      interventionId: $interventionId
      parentFirstName: $parentFirstName
      parentLastName: $parentLastName
      parentDateOfBirth: $parentDateOfBirth
      childDateOfBirth: $childDateOfBirth
    ) {
      ...FacilitatedIntervention
    }
  }
  ${fragments_1.facilitatedInterventionFragment}
`;
exports.AGREE_TO_DATA_COLLECTION = (0, graphql_tag_1.default) `
  mutation AgreeToDataCollection(
    $interventionId: Int!
    $parentFirstName: String
    $parentLastName: String
    $parentDateOfBirth: String!
    $childDateOfBirth: String!
    $email: String
    $phone: String
    $signature: String!
    $content: String!
  ) {
    agreeToDataCollection(
      interventionId: $interventionId
      parentFirstName: $parentFirstName
      parentLastName: $parentLastName
      parentDateOfBirth: $parentDateOfBirth
      childDateOfBirth: $childDateOfBirth
      email: $email
      phone: $phone
      signature: $signature
      content: $content
    ) {
      ...FacilitatedIntervention
    }
  }
  ${fragments_1.facilitatedInterventionFragment}
`;
exports.SEND_RESET_PASSWORD_URL = (0, graphql_tag_1.default) `
  mutation SendResetPasswordUrl($username: String!, $role: String!) {
    sendResetPasswordUrl(username: $username, role: $role)
  }
`;
exports.RESET_PASSWORD_MUTATION = (0, graphql_tag_1.default) `
  mutation ResetPassword($token: String!, $userId: Int!, $password: String!) {
    resetPasswordForUser(token: $token, userId: $userId, password: $password) {
      id
      firstName
      lastName
    }
  }
`;
exports.SHARE_CONTENT = (0, graphql_tag_1.default) `
  mutation ShareContent($shareContentInput: ShareContentInput!) {
    shareContent(shareContentInput: $shareContentInput) {
      status
    }
  }
`;
exports.SIGN_UP_PARTNER = (0, graphql_tag_1.default) `
  mutation SignUpPartner($signUpPartnerInput: SignUpPartnerInput!) {
    signUpPartner(signUpPartnerInput: $signUpPartnerInput) {
      id
      role
    }
  }
`;
exports.SUBMIT_CONTACT_FORM = (0, graphql_tag_1.default) `
  mutation SubmitContactForm(
    $email: String!
    $reason: String!
    $comment: String!
  ) {
    submitContactForm(email: $email, reason: $reason, comment: $comment) {
      status
    }
  }
`;
exports.UPDATE_PARTNER_ASSOCIATION = (0, graphql_tag_1.default) `
  mutation UpdatePartnerAssociation(
    $partnerOrganizationId: Int
    $connectionType: String!
    $connectionName: String!
  ) {
    updatePartnerAssociation(
      partnerOrganizationId: $partnerOrganizationId
      connectionType: $connectionType
      connectionName: $connectionName
    ) {
      id
    }
  }
`;
exports.UPDATE_PREFERRED_LANGUAGE = (0, graphql_tag_1.default) `
  mutation UpdatePreferredLanguage($language: String!) {
    updatePreferredLanguage(language: $language) {
      language
    }
  }
`;
exports.UPDATE_DEMOGRAPHIC_DATA = (0, graphql_tag_1.default) `
  mutation UpdateDemographicData($demographicInput: DemographicInput!) {
    updateDemographicData(demographicInput: $demographicInput) {
      ...Parent
    }
  }
  ${fragments_1.parentFragment}
`;
exports.DECLINE_SURVEY_BY_TYPE = (0, graphql_tag_1.default) `
  mutation DeclineSurveyByType($surveyType: String!) {
    declineSurveyByType(surveyType: $surveyType) {
      id
    }
  }
`;
exports.SAVE_EDUCATOR_QUESTION_ANSWERS = (0, graphql_tag_1.default) `
  mutation SaveEducatorQuestionAnswers(
    $roleQuestionAnswersInput: [RoleQuestionAnswersInput]!
  ) {
    saveEducatorQuestionAnswers(
      roleQuestionAnswersInput: $roleQuestionAnswersInput
    ) {
      status
    }
  }
`;
exports.SAVE_ROLE_QUESTION_ANSWERS = (0, graphql_tag_1.default) `
  mutation SaveRoleQuestionAnswers(
    $roleQuestionAnswersInput: [RoleQuestionAnswersInput]!
  ) {
    saveRoleQuestionAnswers(
      roleQuestionAnswersInput: $roleQuestionAnswersInput
    ) {
      status
    }
  }
`;
exports.GET_INTERVENTION_BY_TMWID = (0, graphql_tag_1.default) `
  mutation GetFacilitatedInterventionByTMWId($tmwId: String!, $seriesId: Int!) {
    facilitatedInterventionByTMWId(tmwId: $tmwId, seriesId: $seriesId) {
      ...FacilitatedIntervention
    }
  }
  ${fragments_1.facilitatedInterventionFragment}
`;
exports.NOTIFY_PARTNER_ACTIVITY = (0, graphql_tag_1.default) `
  mutation NotifyPartnerActivity(
    $userId: String
    $form: String!
    $name: String
    $email: String
    $organization: String
    $date: String
    $comment: String
  ) {
    notifyPartnerActivity(
      userId: $userId
      form: $form
      name: $name
      email: $email
      organization: $organization
      date: $date
      comment: $comment
    ) {
      status
    }
  }
`;
exports.UPDATE_USER_ROLE = (0, graphql_tag_1.default) `
  mutation UpdateUserRole($updateUserRoleInput: UpdateUserRoleInput!) {
    updateUserRole(updateUserRoleInput: $updateUserRoleInput) {
      status
    }
  }
`;
exports.CHANGE_ROLE = (0, graphql_tag_1.default) `
  mutation ChangeRole($roleId: Int!) {
    changeRole(id: $roleId) {
      isSetUpPending
    }
  }
`;
exports.CREATE_USER_ROLE = (0, graphql_tag_1.default) `
  mutation CreateUserRole($createUserRoleInput: CreateUserRoleInputType!) {
    createUserRole(createUserRoleInput: $createUserRoleInput) {
      status
    }
  }
`;
exports.AGREE_TO_PARTICIPATE_ECE_COURSE = (0, graphql_tag_1.default) `
  mutation AgreeToParticipateECECourse {
    agreeToParticipateECECourse {
      status
    }
  }
`;
exports.UPDATE_PARENT_EMAIL = (0, graphql_tag_1.default) `
  mutation UpdateParentEmail($email: String!) {
    updateParentEmail(email: $email) {
      ...Parent
    }
  }
  ${fragments_1.parentFragment}
`;
