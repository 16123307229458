"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firstErrorReasonFrom = void 0;
const firstErrorReasonFrom = (error) => {
    if (!error) {
        return '';
    }
    const first = error.graphQLErrors[0];
    if (first && first.extensions && first.extensions['reason']) {
        return first.extensions['reason'];
    }
    return '';
};
exports.firstErrorReasonFrom = firstErrorReasonFrom;
