import React, { FC, useState } from 'react'
import { RouteComponentProps, Link, navigate } from '@reach/router'
import { Formik, Form } from 'formik'
import { TFunction } from 'i18next'
import { useTranslation, Trans } from 'react-i18next'
import TextField from './TextField'
import colors from '../theme/colors'
import FullScreenModal from './FullScreenModal'
import { isEmpty } from 'lodash'
import { object, string } from 'yup'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { MarkdownModalTitleSection } from './MarkdownTypography'
import { registerPath, forgotPasswordPath, rootPath } from '../paths'
import { accountsId } from '../contentful/ids'
import { IAccountsFields } from '../types/generated/contentful'
import { useLogInMutation, firstErrorReasonFrom } from '@tmw/api-client'
import { PARTNER_ROLE } from '../utils/roles'
import { createClientFriendlyErrorHandler } from '../apolloClient'
import FormMessage from './FormMessage'
import { useCurrentUser } from '../context/currentUser'
import Button from './Button'
import { PASSWORD_EXPIRED } from '../utils/errors'
import { expiredPasswordPath } from '../paths'
import { translateErrorMessage } from '../utils/validators'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'

const useStyles = makeStyles({
  register: {
    color: colors.black.disabled,
  },
  registerLink: {
    color: colors.blue.primary,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 50,
  },
  button: {
    marginTop: 120,
    marginBottom: 10,
    height: 56,
    width: '80%',
    maxWidth: 344,
    letterSpacing: '1.25px',
  },
  forgotPassword: {
    letterSpacing: '1.25px',
  },
})

const makeValidationSchema = (t: TFunction) =>
  object().shape({
    username: string()
      .email(t<string>('validations.email.invalid'))
      .required(t<string>('validations.email.required')),
    password: string().required(t<string>('validations.password.required')),
  })

const Register: FC = () => {
  const classes = useStyles()

  return (
    <Typography variant="body1" className={classes.register}>
      <Trans i18nKey="forms.signIn.buttons.register">
        <Link to={registerPath} className={classes.registerLink} />
      </Trans>
    </Typography>
  )
}

interface IFormValues {
  username: string
  password: string
  role: string
}

const SignIn: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [logIn] = useLogInMutation()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const entry = useContentfulEntry<IAccountsFields>(accountsId)
  const { setCurrentUser } = useCurrentUser()

  if (!entry) return null
  const {
    fields: { signInTitle: title },
  } = entry

  const initialValues = {
    username: '',
    password: '',
    role: PARTNER_ROLE,
  }

  const onSubmit = async (values: IFormValues) => {
    const errorHandler = createClientFriendlyErrorHandler(setErrorMessage)

    try {
      const { data } = await logIn({ variables: values })
      if (data) {
        const { logIn: user } = data
        setCurrentUser(user)
        navigate(rootPath)
      }
    } catch (error: any) {
      if (firstErrorReasonFrom(error) === PASSWORD_EXPIRED) {
        return navigate(expiredPasswordPath, { state: values })
      }
      errorHandler(error)
    }
  }

  const onForgotPassword = () => {
    navigate(forgotPasswordPath)
  }

  return (
    <FullScreenModal alternateOption={<Register />} size="small">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={makeValidationSchema(t)}
      >
        {({ errors }) => {
          return (
            <Form>
              <div className={classes.container}>
                <MarkdownModalTitleSection
                  source={title}
                  className={classes.title}
                />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label={t('forms.signIn.fields.email')}
                      name="username"
                      fullWidth
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('forms.signIn.fields.password')}
                      name="password"
                      type="password"
                      fullWidth
                      variant="filled"
                    />
                  </Grid>
                </Grid>
                <FormMessage
                  type="error"
                  message={translateErrorMessage(
                    t,
                    'forms.signIn.errorMessages',
                    errorMessage
                  )}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isEmpty(errors)}
                  className={classes.button}
                >
                  {t('forms.signIn.buttons.submit')}
                </Button>
                <Button className={classes.forgotPassword} color="primary" onClick={onForgotPassword}>
                  {t('forms.signIn.buttons.forgotPassword')}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </FullScreenModal>
  )
}

export default SignIn
