import React, { FC } from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'

import Home from './components/Home'
import Training from './components/Training'
import Resources from './components/Resources'
import Ambassadors from './components/Ambassadors'

import HowTo from './components/HowTo'
import LearnMore from './components/LearnMore'

import Router from './components/Router'

import PageWrapper from './components/PageWrapper'
import Register from './components/Register'
import SignIn from './components/SignIn'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import ExpiredPassword from './components/ExpiredPassword'
import RolloutForm from './components/RolloutForm'
import StaffTraining from './components/StaffTraining'
import LandingPage from './components/Landing'
import { useCurrentUser } from './context/currentUser'
import {
  welcomePath,
  rootPath,
  trainingPath,
  resourcesPath,
  howToPath,
  registerPath,
  signInPath,
  forgotPasswordPath,
  resetPasswordPath,
  expiredPasswordPath,
  ambassadorsPath,
  staffTrainingPath,
  logoutPath,
  learnMorePath,
  rolloutPath,
  contactPath,
} from './paths'
import Logout from './components/Logout'
import Contact from './components/Contact'

const NotFound: FC<RouteComponentProps> = () => (
  <Redirect to={rootPath} noThrow />
)

interface AuthenticationRouteProps extends RouteComponentProps {
  component: React.ComponentType<RouteComponentProps>
}

const AuthenticatedRoute = ({
  component: Component,
  ...props
}: AuthenticationRouteProps) => {
  const { isSignedIn } = useCurrentUser()
  return isSignedIn ? (
    <Component {...props} />
  ) : (
    <Redirect to={welcomePath} noThrow />
  )
}

const UnauthenticatedRoute = ({
  component: Component,
  ...props
}: AuthenticationRouteProps) => {
  const { isSignedIn } = useCurrentUser()
  return isSignedIn ? (
    <Redirect to={rootPath} noThrow />
  ) : (
    <Component {...props} />
  )
}

const UnauthenticatedRouteWithoutSigninChk = ({
  component: Component,
  ...props
}: AuthenticationRouteProps) => {
  return <Component {...props} />
}

const Routes = () => (
  <Router>
    <PageWrapper path="/">
      <AuthenticatedRoute component={Home} path={rootPath} />
      <AuthenticatedRoute component={Training} path={trainingPath} />
      <AuthenticatedRoute component={Resources} path={resourcesPath} />
      <AuthenticatedRoute component={LearnMore} path={learnMorePath} />
      <AuthenticatedRoute component={RolloutForm} path={rolloutPath} />
      <AuthenticatedRoute component={HowTo} path={howToPath} />
      <AuthenticatedRoute component={Logout} path={logoutPath} />

      <UnauthenticatedRoute component={LandingPage} path={welcomePath} />
      <UnauthenticatedRouteWithoutSigninChk
        component={Contact}
        path={contactPath}
      />

      <Ambassadors path={`${ambassadorsPath}/*`} />
      <StaffTraining path={staffTrainingPath} />
      <NotFound default />
    </PageWrapper>

    <UnauthenticatedRoute component={Register} path={`${registerPath}/*`} />
    <UnauthenticatedRoute component={SignIn} path={signInPath} />
    <UnauthenticatedRoute
      component={ForgotPassword}
      path={forgotPasswordPath}
    />
    <UnauthenticatedRoute component={ResetPassword} path={resetPasswordPath} />
    <UnauthenticatedRoute
      component={ExpiredPassword}
      path={expiredPasswordPath}
    />
  </Router>
)

export default Routes
