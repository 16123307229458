import { Backdrop, Modal, ModalProps } from '@mui/material'
import React, { FC } from 'react'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  lightBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
})

const Lightbox: FC<ModalProps> = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Modal
      className={classes.lightBox}
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      {...props}
    >
      {children}
    </Modal>
  )
}

export default Lightbox
