import React, { FC } from 'react'
import Markdown from 'react-markdown'
import cx from 'classnames'
import { makeStyles } from '@mui/styles'
import { Card, CardMedia, Theme, Typography } from '@mui/material'

import colors from '../../theme/colors'
import { boldFontFamily } from '../../theme'
import Link from '../Link'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    background: 'transparent',
  },
  media: {
    position: 'relative',
    height: '100%',
    paddingTop: '90%',
    backgroundSize: 'cover',
  },
  calloutBody: {
    color: colors.white.primary,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
    '& a': {
      color: colors.white.primary,
    },
    '& li': {
      fontWeight: 400,
    },
    [breakpoints.down('xs')]: {
      fontWeight: 400,
    },
  },
  overlayContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    paddingTop: 20,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  overlayContainerOtherContent: {
    backgroundColor: colors.purple.primary,
  },
  overline: {
    marginBottom: 8,
    color: colors.white.primary,
  },
  title: {
    marginRight: 16,
    color: colors.white.primary,
    fontFamily: boldFontFamily,
  },
  flair: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 'auto',
  },
  rightFlair: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'auto',
  },
  tapToBegin: {
    fontSize: '0.875rem',
    color: colors.white.primary,
    lineHeight: '1rem',
    letterSpacing: `${1.25 / 14}em`,
  },
  chip: {
    padding: 6,
    color: colors.black.medium,
  },
  circleIcon: {
    marginLeft: 0,
    color: '#6F993A',
  },
  lockIcon: {
    marginLeft: 0,
    color: colors.black.inactive,
  },
}))

type ContentCardProps = {
  title: string
  description: string
  imageUrl?: string
}

const ContentCard: FC<ContentCardProps> = ({
  title,
  description,
  imageUrl,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card} elevation={3}>
      <CardMedia className={classes.media} image={imageUrl}>
        <div
          className={cx(
            classes.overlayContainer,
            !imageUrl && classes.overlayContainerOtherContent
          )}
        >
          <Typography variant="h5" component="h1" className={classes.overline}>
            {title}
          </Typography>
          <Markdown
            className={classes.calloutBody}
            children={description}
            components={{
              a: (props: any) => <Link {...props} />,
              p: (props: any) => (
                <Typography
                  variant="body1"
                  className={classes.calloutBody}
                  {...props}
                />
              ),
            }}
          />
        </div>
      </CardMedia>
    </Card>
  )
}

export default ContentCard
