import React from 'react';
var svgs = {
    'light-background': 'https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg',
    'dark-background': 'https://images.ctfassets.net/fo9twyrwpveg/7F5pMEOhJ6Y2WukCa2cYws/398e290725ef2d3b3f0f5a73ae8401d6/PoweredByContentful_DarkBackground.svg',
    'dark-background-monochrome': 'https://images.ctfassets.net/fo9twyrwpveg/7Htleo27dKYua8gio8UEUy/0797152a2d2f8e41db49ecbf1ccffdaa/PoweredByContentful_DarkBackground_MonochromeLogo.svg',
};
var ContentfulLogo = function (_a) {
    var type = _a.type;
    return (React.createElement("a", { href: "https://www.contentful.com/", rel: "nofollow noopener noreferrer", target: "_blank" },
        React.createElement("img", { alt: "Powered by Contentful", style: {
                maxWidth: 100,
                marginTop: 45,
                width: 100,
            }, src: svgs[type] })));
};
export default ContentfulLogo;
