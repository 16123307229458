import { FC } from 'react'
import { MarkdownPageBodySection } from './MarkdownTypography'
import React from 'react'
import Button from './Button'
import colors from '../theme/colors'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'

interface QuestionsCalloutProps {
  calloutText?: string
  emailButtonPath?: string
  emailButtonText?: string
  scheduleCallButtonText?: string
  scheduleCallButtonUrl?: string
}

const useStyles = makeStyles({
  appCallout: {
    backgroundColor: colors.purple.primary,
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 45,
    marginTop: 64,
    padding: 64,
  },
  appCalloutText: {
    color: colors.white.primary,
    marginBottom: 64,
    textAlign: 'center',
    '& *': {
      color: colors.white.primary,
    },
  },
  scheduleCallButton: {
    minWidth: '100%',
    padding: '20px 8px',
  },
})

const QuestionsCallout: FC<QuestionsCalloutProps> = ({
  calloutText,
  emailButtonPath,
  emailButtonText,
  scheduleCallButtonText,
  scheduleCallButtonUrl,
}) => {
  const classes = useStyles()

  return (
    <Grid xs={12} item className={classes.appCallout}>
      {calloutText && (
        <MarkdownPageBodySection
          className={classes.appCalloutText}
          source={calloutText}
        />
      )}
      <Grid container direction="row" justifyContent="center" spacing={4}>
        <Grid xs={12} sm={6} md={6} lg={3} item>
          <a href={emailButtonPath} target="_blank" rel="noopener noreferrer">
            <Button
              className={classes.scheduleCallButton}
              color="secondary"
              variant="outlined"
            >
              {emailButtonText}
            </Button>
          </a>
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={3} item>
          <a
            href={scheduleCallButtonUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              className={classes.scheduleCallButton}
              color="secondary"
              variant="outlined"
            >
              {scheduleCallButtonText}
            </Button>
          </a>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuestionsCallout
