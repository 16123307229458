import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  MarkdownPageBodySection,
  MarkdownPageTitleSection,
} from './MarkdownTypography'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { IRolloutPageFields } from '../types/generated/contentful'
import { ContactForm } from './ContactForm'
import { rolloutPageId } from '../contentful/ids'
import { makeStyles } from '@mui/styles'
import { Container } from '@mui/material'

let styles = {
  body: {
    marginBottom: 25,
  },
}

const useStyles = makeStyles(styles)

const RolloutForm: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const entry = useContentfulEntry<IRolloutPageFields>(rolloutPageId)

  if (!entry) return null

  const {
    fields: { title, body },
  } = entry

  return (
    <Container>
      <header>
        <MarkdownPageTitleSection source={title} />
      </header>
      <MarkdownPageBodySection source={body} className={classes.body} />
      <ContactForm formType="rollout" />
    </Container>
  )
}

export default RolloutForm
