import React, { FC, useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'

import { useCurrentUser } from '../context/currentUser'
import Loading from './Loading'
import { rootPath } from '../paths'
import { useLogOutMutation } from '@tmw/api-client'

const Logout: FC<RouteComponentProps> = () => {
  const { resetCurrentUser } = useCurrentUser()

  const [signOut] = useLogOutMutation({
    onCompleted: () => {
      resetCurrentUser()
    },
    onError: () => {
      navigate(rootPath)
    },
  })

  useEffect(() => {
    signOut()
  }, [signOut])
  return <Loading />
}

export default Logout
