import React, { FC, useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { Formik, Form } from 'formik'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import FullScreenModal from './FullScreenModal'
import { isEmpty } from 'lodash'
import { object, string, ref } from 'yup'
import { MarkdownModalTitleSection } from './MarkdownTypography'
import { passwordValidator, PASSWORD_REGEX } from '../utils/validators'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { accountsId } from '../contentful/ids'
import { IAccountsFields } from '../types/generated/contentful'
import { useChangePasswordMutation } from '@tmw/api-client'
import { createClientFriendlyErrorHandler } from '../apolloClient'
import { rootPath, signInPath } from '../paths'
import FormMessage from './FormMessage'
import Button from './Button'
import PasswordTextField from './PasswordTextField'
import { useCurrentUser } from '../context/currentUser'
import { PasswordRule, PasswordRules } from './PasswordRules'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 50,
  },
  button: {
    marginTop: 80,
    height: 56,
    width: '80%',
    maxWidth: 344,
  },
  gridItem: {
    padding: 12,
    margin: 0,
    boxSizing: 'border-box',
  },
})

const makeValidationSchema = (t: TFunction) =>
  object().shape({
    newPassword: passwordValidator(t).required(
      t<string>('validations.password.required')
    ),
    confirmNewPassword: string()
      .required(t<string>('validations.confirmPassword.required'))
      .oneOf(
        [ref('newPassword')],
        t<string>('validations.confirmPassword.match')
      ),
  })

interface IFormValues {
  newPassword: string
  confirmNewPassword: string
}

interface IExpiredPasswordState {
  username: string
  password: string
  role: string
}

const ExpiredPassword: FC<RouteComponentProps> = ({ location }) => {
  const expiredPasswordState = location?.state as IExpiredPasswordState
  const username = expiredPasswordState.username
  const oldPassword = expiredPasswordState.password
  const role = expiredPasswordState.role
  if (!username || !oldPassword || !role) {
    navigate(signInPath)
  }
  const classes = useStyles()
  const { t } = useTranslation()
  const { setCurrentUser } = useCurrentUser()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [showForm, setShowForm] = useState<boolean>(false)
  const [isValidPassword, setPasswordValid] = useState<boolean>(false)
  const [changePassword] = useChangePasswordMutation()
  const entry = useContentfulEntry<IAccountsFields>(accountsId)
  if (!entry) return null
  const {
    fields: {
      changePasswordTitle: title,
      changePasswordInstructions: instructions,
    },
  } = entry

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  }

  const onSubmit = async (values: IFormValues) => {
    const errorHandler = createClientFriendlyErrorHandler(setErrorMessage)

    try {
      const { data } = await changePassword({
        variables: {
          changePasswordInput: {
            username,
            role,
            oldPassword,
            newPassword: values.newPassword,
          },
        },
      })
      // @ts-ignore
      const { changePassword: user } = data
      setCurrentUser(user)
      navigate(rootPath)
    } catch (error) {
      errorHandler(error)
    }
  }

  const validatePassword = (password: string) => {
    if (
      password === '' ||
      password.length < 8 ||
      !password.match(PASSWORD_REGEX)
    ) {
      setPasswordValid(false)
    } else {
      setPasswordValid(true)
    }
  }

  return (
    <FullScreenModal size="small">
      {!showForm ? (
        <div className={classes.container}>
          <MarkdownModalTitleSection
            source={instructions}
            className={classes.title}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setShowForm(true)}
          >
            {t('forms.changePassword.buttons.next')}
          </Button>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={makeValidationSchema(t)}
        >
          {({ errors }) => {
            return (
              <Form>
                <div className={classes.container}>
                  <MarkdownModalTitleSection
                    source={title}
                    className={classes.title}
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <PasswordTextField
                        label={t('forms.changePassword.fields.password')}
                        name="newPassword"
                        variant="filled"
                        isValidPassword={isValidPassword}
                        validatePassword={validatePassword}
                        helperText={t<string>(
                          'validations.password.validPassword'
                        )}
                      />
                    </Grid>{' '}
                    <Grid item xs={12}>
                      <PasswordTextField
                        label={t('forms.changePassword.fields.confirmPassword')}
                        name="confirmNewPassword"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <PasswordRules>
                        <PasswordRule>
                          {t<string>('validations.password.length')}
                        </PasswordRule>
                        <PasswordRule>
                          {t<string>('validations.password.upperCase')}
                        </PasswordRule>
                        <PasswordRule>
                          {t<string>('validations.password.number')}
                        </PasswordRule>
                        <PasswordRule>
                          {t<string>('validations.password.specialCharacter')}
                        </PasswordRule>
                      </PasswordRules>
                    </Grid>
                  </Grid>
                  <FormMessage type="error" message={errorMessage} />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isEmpty(errors)}
                    className={classes.button}
                  >
                    {t('forms.changePassword.buttons.submit')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </FullScreenModal>
  )
}

export default ExpiredPassword
