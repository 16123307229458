"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.firstErrorReasonFrom = exports.PlatformApiLink = exports.useTypedQuery = exports.useMutation = exports.useLazyTypedQuery = exports.setupHooks = void 0;
__exportStar(require("./generated"), exports);
__exportStar(require("./types"), exports);
var client_1 = require("./client");
Object.defineProperty(exports, "setupHooks", { enumerable: true, get: function () { return client_1.setupHooks; } });
Object.defineProperty(exports, "useLazyTypedQuery", { enumerable: true, get: function () { return client_1.useLazyTypedQuery; } });
Object.defineProperty(exports, "useMutation", { enumerable: true, get: function () { return client_1.useMutation; } });
Object.defineProperty(exports, "useTypedQuery", { enumerable: true, get: function () { return client_1.useTypedQuery; } });
var PlatformApiLink_1 = require("./PlatformApiLink");
Object.defineProperty(exports, "PlatformApiLink", { enumerable: true, get: function () { return PlatformApiLink_1.PlatformApiLink; } });
var errorReason_1 = require("./errorReason");
Object.defineProperty(exports, "firstErrorReasonFrom", { enumerable: true, get: function () { return errorReason_1.firstErrorReasonFrom; } });
