import React, { FC } from 'react'
import {
  ButtonProps as MUIButtonProps,
  Button as MUIButton,
} from '@mui/material'
import CapitalizationTransformer from '@tmw/shared-components/lib/CapitalizeTransformer'

export interface ButtonProps extends MUIButtonProps {
  className?: string
  component?: string
  download?: string
  target?: string
}

export const ExtendedButton: FC<ButtonProps> = ({ children, ...props }) => (
  <MUIButton {...props}>{children}</MUIButton>
)

const Button: FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <MUIButton className={className} {...props}>
      <CapitalizationTransformer>{children}</CapitalizationTransformer>
    </MUIButton>
  )
}

export default Button
