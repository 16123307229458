import React, { FC } from 'react'
import { IQuoteBlock } from '../types/generated/contentful'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'

const useStyles = makeStyles({
  quoteBlock: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  quoteImg: {
    borderRadius: '100%',
    height: 122,
    marginBottom: 24,
  },
})

interface QuoteBlockProps {
  entry: IQuoteBlock
}

const QuoteBlock: FC<QuoteBlockProps> = ({ entry, ...props }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={4} className={classes.quoteBlock} {...props}>
      <img
        className={classes.quoteImg}
        alt={entry.fields.image.fields.description}
        src={entry.fields.image.fields.file.url}
      />
      <Typography variant="body1" align="center">
        {entry.fields.text}
      </Typography>
    </Grid>
  )
}

export default QuoteBlock
