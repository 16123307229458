import React, { FC } from 'react'
import { Field, FieldProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

interface DatePickerProps {
  id: string
  name: string
  label: string
  disableFuture?: boolean
  fullWidth?: boolean
  className: string
}

interface DatePickerInputProps extends DatePickerProps, FieldProps {}

const DatePickerInput: FC<DatePickerInputProps> = ({
  id,
  label,
  field: { name, value },
  form: { setFieldValue, errors, touched },
  disableFuture = false,
  fullWidth = false,
  className,
}) => {
  const error = getIn(errors, name)
  const showError = getIn(touched, name) && !!error
  const { t } = useTranslation()

  return (
    <MobileDatePicker
      label={label}
      format="MM/DD/YYYY"
      value={value === undefined ? null : value}
      disableFuture={disableFuture}
      onChange={(date: any) => {
        setFieldValue(name, date)
      }}
      className={className}
      views={['year', 'month', 'day']}
      slotProps={{
        textField: {
          'aria-label': t<string>('common.datePickerAriaLabel'),
          name: name,
          id: id,
          placeholder: t<string>('validations.date.placeholder'),
          error: showError || error,
          helperText: error,
          fullWidth: fullWidth,
          variant: 'filled',
        },
        field: {
          InputProps: {
            endAdornment: <InsertInvitationIcon sx={{ cursor: 'pointer' }} />,
          },
        },
        layout: {
          value: value ?? moment(),
          view: 'day',
        },
      }}
      localeText={{
        toolbarTitle: value ? value.year() : moment().year(),
      }}
    />
  )
}

const MuiDatePicker: FC<DatePickerProps> = props => (
  <Field component={DatePickerInput} {...props} />
)

export default MuiDatePicker
