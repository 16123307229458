import i18n from 'i18next'
import i18nextContentfulBackend from './contentful/i18nextContentfulBackend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const english = 'en-US'

export const supportedLanguages = [english]
const languageDetector = new LanguageDetector()

i18n
  .use(languageDetector)
  .use(i18nextContentfulBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: english,
    load: 'currentOnly',
    detection: {
      order: ['localStorage', 'navigator'],
    },
    supportedLngs: supportedLanguages,
  })

export default i18n
