import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import classnames from 'classnames'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { IHomePageFields } from '../types/generated/contentful'
import MarkdownTypography from './MarkdownTypography'
import CalloutSection from './CalloutSection'
import QuoteBlock from './QuoteBlock'
import Hero from './Hero'
import { makeStyles } from '@mui/styles'
import { Container, Grid, Theme, Typography } from '@mui/material'
import Link from './Link'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  parentTitle: {
    marginBottom: 24,
  },
  section: {
    marginBottom: 64,
  },
  text: {
    marginTop: 24,
    whiteSpace: 'pre-wrap',
  },
  title: {
    marginTop: 64,
  },
}))

const Home: FC<RouteComponentProps> = () => {
  const entry = useContentfulEntry<IHomePageFields>('5uKACxSmpsZxzDXVhc5CK')
  const classes = useStyles()

  if (!entry) return null

  const {
    fields: {
      heroSectionBackgroundImages,
      heroSectionTitle,
      heroSectionSubtitle,
      video,
      videoCallToAction,
      introductionSection,
      quoteBlockSectionTitle,
      quoteBlocks,
      calloutSections,
    },
  } = entry

  return (
    <>
      <Hero
        title={heroSectionTitle}
        subtitle={heroSectionSubtitle}
        backgroundImages={heroSectionBackgroundImages}
        video={video}
        videoCallToAction={videoCallToAction}
      />
      <Container>
        <Grid container>
          <Grid xs={12} item>
            <MarkdownTypography
              source={introductionSection}
              renderers={{
                h1: props => (
                  <Typography
                    className={classes.title}
                    color="primary"
                    variant="h4"
                    component="h2"
                    {...props}
                  />
                ),
                p: props => (
                  <Typography
                    className={classes.text}
                    variant="body1"
                    {...props}
                  />
                ),
                a: (props: any) => <Link {...props} />,
              }}
            />
          </Grid>
          <Grid className={classes.section} container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <Typography
                className={classnames(classes.title, classes.parentTitle)}
                color="primary"
                variant="h4"
                component="h2"
              >
                {quoteBlockSectionTitle}
              </Typography>
            </Grid>
            {quoteBlocks!.map(block => (
              <QuoteBlock entry={block} key={block.sys.id} />
            ))}
          </Grid>
          <Grid container spacing={3} sx={{ margin: '-12px' }}>
            {calloutSections &&
              calloutSections.map(callout => (
                <CalloutSection
                  key={callout.sys.id}
                  entry={callout}
                  itemSize={4}
                />
              ))}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Home
