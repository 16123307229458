import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { ILearnMoreFields } from '../types/generated/contentful'
import {
  MarkdownPageTitleSection,
  MarkdownPageBodySection,
} from './MarkdownTypography'
import FAQEntry from './FAQEntry'
import colors from '../theme/colors'
import QuestionsCallout from './QuestionsCallout'
import CalloutSection from './CalloutSection'
import { learnMoreId } from '../contentful/ids'
import { makeStyles } from '@mui/styles'
import { Container, Grid } from '@mui/material'

const useStyles = makeStyles({
  heroText: {
    color: colors.white.primary,
    paddingTop: 25,
    paddingBottom: 25,
  },
  menuOfResourcesImage: {
    width: '100%',
  },
})

const LearnMore: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const entry = useContentfulEntry<ILearnMoreFields>(learnMoreId)

  if (!entry) return null

  const {
    fields: {
      title,
      campaignOrigin,
      menuOfResourcesTitle,
      menuOfResourcesImage,
      tmwRole,
      firstAccordionSectionIntro,
      firstAccordionSectionEntries,
      secondAccordionSectionIntro,
      secondAccordionSectionEntries,
      calloutText,
      emailButtonText,
      emailButtonPath,
      scheduleCallButtonText,
      scheduleCallButtonUrl,
      calloutSections,
    },
  } = entry

  return (
    <Container>
      <Grid>
        <Grid xs={12} item>
          <MarkdownPageTitleSection source={title} />
          <MarkdownPageBodySection source={campaignOrigin} />
        </Grid>
        <Grid xs={12} item>
          {menuOfResourcesTitle && (
            <MarkdownPageBodySection source={menuOfResourcesTitle} />
          )}
          {menuOfResourcesImage && (
            <img
              className={classes.menuOfResourcesImage}
              src={menuOfResourcesImage.fields.file.url}
              alt={menuOfResourcesImage.fields.description}
            />
          )}
        </Grid>
        <Grid xs={12} item>
          {tmwRole && <MarkdownPageBodySection source={tmwRole} />}
        </Grid>
        <Grid xs={12} item>
          {firstAccordionSectionIntro && (
            <MarkdownPageBodySection source={firstAccordionSectionIntro} />
          )}
        </Grid>
        <Grid xs={12} item>
          {firstAccordionSectionEntries &&
            firstAccordionSectionEntries.map(item => {
              return <FAQEntry item={item} key={item.sys.id} />
            })}
        </Grid>
        <Grid xs={12} item>
          {secondAccordionSectionIntro && (
            <MarkdownPageBodySection source={secondAccordionSectionIntro} />
          )}
        </Grid>
        <Grid xs={12} item>
          {secondAccordionSectionEntries &&
            secondAccordionSectionEntries.map(item => {
              return <FAQEntry item={item} key={item.sys.id} />
            })}
        </Grid>
        <QuestionsCallout
          calloutText={calloutText}
          emailButtonPath={emailButtonPath}
          emailButtonText={emailButtonText}
          scheduleCallButtonText={scheduleCallButtonText}
          scheduleCallButtonUrl={scheduleCallButtonUrl}
        />
        <Grid xs={12} item container>
          <Grid container spacing={2}>
            {calloutSections &&
              calloutSections.map(callout => (
                <CalloutSection
                  key={callout.sys.id}
                  entry={callout}
                  itemSize={6}
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LearnMore
