import React, { FC } from 'react'
import Markdown from 'react-markdown'
import { boldFontFamily } from '../theme'
import Link from './Link'
import colors from '../theme/colors'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface MarkdownTypographyProps {
  className?: string
  escapeHtml?: boolean
  source: string
  renderers?: {
    [nodeType: string]: React.ElementType<any>
  }
}

const MarkdownTypography: FC<MarkdownTypographyProps> = ({
  className,
  escapeHtml = false,
  source,
  renderers,
}) => {
  const defaultRenderers = {
    a: (props: any) => <Link {...props} />,
    p: (props: any) => <Typography variant="body1" {...props} />,
    strong: (props: any) => (
      <Typography
        component="span"
        style={{ fontFamily: boldFontFamily }}
        variant="body1"
        {...props}
      />
    ),
  }

  return (
    <Markdown
      className={className}
      skipHtml={escapeHtml}
      children={source}
      components={{ ...defaultRenderers, ...renderers }}
    />
  )
}

export default MarkdownTypography

const usePageBodyStyles = makeStyles({
  body: {
    '& > p + p': {
      marginTop: 24,
    },
    fontSize: '0.875rem',
  },
  bodyTitle: {
    marginBottom: 12,
    marginTop: 24,
  },
})

export const MarkdownPageBodySection: FC<MarkdownTypographyProps> = props => {
  const classes = usePageBodyStyles()

  return (
    <MarkdownTypography
      className={classes.body}
      renderers={{
        a: (props: any) => <Link {...props} />,
        h1: props => (
          <Typography
            component="h2"
            variant="h5"
            className={classes.bodyTitle}
            {...props}
          />
        ),
        h2: props => (
          <Typography
            component="h2"
            variant="h5"
            className={classes.bodyTitle}
            {...props}
          />
        ),
        ul: props => <ul style={{ lineHeight: 1.43 }} {...props} />,
      }}
      {...props}
    />
  )
}

const usePageTitleStyles = makeStyles({
  pageTitle: {
    color: colors.blue.primary,
    marginBottom: 24,
    marginTop: 76,
  },
  pageSubtitle: {
    color: colors.blue.primary,
    marginBottom: 24,
  },
})

export const MarkdownPageTitleSection: FC<MarkdownTypographyProps> = props => {
  const classes = usePageTitleStyles()

  return (
    <MarkdownTypography
      renderers={{
        h1: props => (
          <Typography
            component="h1"
            variant="h4"
            className={classes.pageTitle}
            {...props}
          />
        ),
        h2: props => (
          <Typography
            component="h1"
            variant="h4"
            className={classes.pageTitle}
            {...props}
          />
        ),
        p: props => (
          <Typography
            component="p"
            variant="h5"
            className={classes.pageSubtitle}
            {...props}
          />
        ),
      }}
      {...props}
    />
  )
}

const useModalTitleStyles = makeStyles({
  modalTitle: {
    color: colors.blue.primary,
    marginBottom: 17,
  },
  modalSubtitle: {
    color: colors.blue.primary,
  },
})

export const MarkdownModalTitleSection: FC<MarkdownTypographyProps> = props => {
  const classes = useModalTitleStyles()

  return (
    <MarkdownTypography
      renderers={{
        h1: props => (
          <Typography
            component="h1"
            variant="h5"
            align="center"
            className={classes.modalTitle}
            {...props}
          />
        ),
        p: props => (
          <Typography
            component="p"
            variant="h6"
            align="center"
            className={classes.modalSubtitle}
            {...props}
          />
        ),
      }}
      {...props}
    />
  )
}
