import React, { FC } from 'react'
import { makeStyles } from '@mui/styles'
import { IHowToAccordionEntry } from '../types/generated/contentful'
import Link from './Link'
import Button from './Button'

interface Props {
  item: IHowToAccordionEntry
}

export const ButtonStyles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    padding: '20px 8px',
    marginTop: 25,
    marginBottom: 35,
    minWidth: 344,
  },
}

const useStyles = makeStyles(ButtonStyles)

export const FAQEntryButton: FC<Props> = ({ item }) => {
  const classes = useStyles()

  if (!item.fields.buttonText) {
    return null
  }

  return (
    <div className={classes.buttonContainer}>
      <Link href={item.fields.buttonUrl} underline="none">
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          component="button"
        >
          {item.fields.buttonText}
        </Button>
      </Link>
    </div>
  )
}
