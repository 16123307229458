import { RouteComponentProps } from '@reach/router'
import { FC } from 'react'
import React from 'react'
import Link from './Link'
import colors from '../theme/colors'
import { useContentfulEntries } from '../contentful/useContentfulEntry'
import { ILandingPageFields } from '../types/generated/contentful'
import { registerPath, signInPath, contactPath } from '../paths'
import { Asset } from 'contentful'
import {
  landingHomePageId,
  getStartedContentId,
  whoContentId,
  whatContentId,
  howContentId,
} from '../contentful/ids'
import Markdown from 'react-markdown'
import Button from './Button'
import ContentfulLogo from '@tmw/shared-components/lib/ContentfulLogo'
import ContentCard from './ContentCard'
import classNames from 'classnames'

// fallback image urls
import getStartedImageUrl from '../assets/GetStarted.png'
import whoImageUrl from '../assets/Who.png'
import whatImageUrl from '../assets/What.png'
import howImageUrl from '../assets/How.png'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Theme,
  Typography,
} from '@mui/material'

interface StyleProps {
  backgroundImage?: Asset
}

const useStyles = makeStyles<Theme, StyleProps>(({ breakpoints }) => ({
  // layout
  rootContainer: {
    backgroundAttachment: 'initial',
    backgroundOrigin: 'initial',
    backgroundClip: 'initial',
    backgroundColor: colors.blue.primary,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  mainContainer: {
    marginBottom: 120,
  },
  backgroundContainer: ({ backgroundImage }) => ({
    backgroundImage: backgroundImage
      ? `url(${backgroundImage.fields.file.url})`
      : '',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'auto 550px',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'initial',
    backgroundOrigin: 'initial',
    backgroundClip: 'initial',
    backgroundColor: colors.blue.primary,
    [breakpoints.down('xs')]: {
      background: 'none',
    },
  }),
  cardItem: {
    height: 555,
    width: 378,
    margin: '0 30px',
    [breakpoints.down('xs')]: {
      height: 500,
      width: 290,
      margin: '0 24px',
    },
  },

  // top section
  signInCardContent: {
    height: 555,
    width: 374,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      height: 500,
      width: 290,
    },
  },
  calloutTitle: {
    color: colors.blue.primary,
    marginTop: 25,
    textAlign: 'center',
  },
  calloutBody: {
    color: colors.blue.primary,
    marginTop: 30,
    fontSize: '1.25rem',
    marginBottom: 30,
    maxWidth: 330,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  button: {
    minWidth: 265,
    minHeight: 56,
    fontSize: '1rem',
    letterSpacing: 1.25,
    '& *::hover': {
      textDecoration: 'none !imporant',
    },
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'center',
  },

  // middle section
  multipleCardsContainer: {
    flexDirection: 'row',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  multipleCardsSpacing: {
    margin: 20,
  },

  // footer section
  contentfulLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '2%',
    left: 0,
    right: 0,
    [breakpoints.down('xs')]: {
      bottom: 20,
    },
  },
}))

const getContentById: any = (entries: Array<any>, id: string) => {
  let entry
  entries?.forEach(e => {
    if (e.sys.id === id) entry = e
  })
  return entry
}

const LandingPage: FC<RouteComponentProps> = () => {
  const entries = useContentfulEntries<ILandingPageFields>()
  const mainPage = getContentById(entries?.items, landingHomePageId)
  const classes = useStyles({
    backgroundImage: mainPage?.fields.backgroundImage,
  })

  if (!entries || entries?.total < 5) return null

  const {
    fields: { title, body, registrationButton, signInButton, contactButton },
  } = mainPage

  const whoContent = getContentById(entries?.items, whoContentId)
  const whatContent = getContentById(entries?.items, whatContentId)
  const howContent = getContentById(entries?.items, howContentId)
  const getStartedContent = getContentById(entries?.items, getStartedContentId)

  const {
    fields: {
      title: whoTitle,
      body: whoDescription,
      backgroundImage: whoImage,
    },
  } = whoContent

  const {
    fields: {
      title: whatTitle,
      body: whatDescription,
      backgroundImage: whatImage,
    },
  } = whatContent

  const {
    fields: {
      title: howTitle,
      body: howDescription,
      backgroundImage: howImage,
    },
  } = howContent

  const {
    fields: {
      title: getStartedTitle,
      body: getStartedDescription,
      backgroundImage: getStartedImage,
    },
  } = getStartedContent

  return (
    <div className={classes.rootContainer}>
      <Container>
        <div className={classes.mainContainer}>
          <Box pt={6}>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.backgroundContainer}
                wrap="nowrap"
              >
                <Grid item>
                  <div className={classes.cardItem}>
                    <Card className={classes.card}>
                      <CardContent className={classes.signInCardContent}>
                        <Typography
                          variant="h5"
                          component="h1"
                          className={classes.calloutTitle}
                        >
                          {title}
                        </Typography>

                        <Markdown
                          className={classes.calloutBody}
                          children={body}
                          components={{
                            link: (props: any) => <Link {...props} />,
                            p: (props: any) => (
                              <Typography
                                variant="body1"
                                className={classes.calloutBody}
                                {...props}
                              />
                            ),
                          }}
                        />

                        <div className={classes.buttonContainer}>
                          <Link to={registerPath} underline="none">
                            <Button
                              className={classes.button}
                              color="primary"
                              variant="contained"
                            >
                              {registrationButton}
                            </Button>
                          </Link>
                        </div>
                        <div className={classes.buttonContainer}>
                          <Link to={signInPath} underline="none">
                            <Button
                              className={classes.button}
                              color="primary"
                              variant="contained"
                            >
                              {signInButton}
                            </Button>
                          </Link>
                        </div>
                        <div className={classes.buttonContainer}>
                          <Link to={contactPath} underline="none">
                            <Button
                              className={classes.button}
                              color="primary"
                              variant="contained"
                            >
                              {contactButton || 'Send A Question'}
                            </Button>
                          </Link>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
          <Box pt={4}>
            <div>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
                className={classes.multipleCardsContainer}
              >
                <Grid item>
                  <div
                    className={classNames(
                      classes.cardItem,
                      classes.multipleCardsSpacing
                    )}
                  >
                    <ContentCard
                      title={whoTitle}
                      description={whoDescription}
                      imageUrl={whoImage.fields.file.url || whoImageUrl}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className={classNames(
                      classes.cardItem,
                      classes.multipleCardsSpacing
                    )}
                  >
                    <ContentCard
                      title={whatTitle}
                      description={whatDescription}
                      imageUrl={whatImage.fields.file.url || whatImageUrl}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className={classNames(
                      classes.cardItem,
                      classes.multipleCardsSpacing
                    )}
                  >
                    <ContentCard
                      title={howTitle}
                      description={howDescription}
                      imageUrl={howImage.fields.file.url || howImageUrl}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
          <Box pt={4}>
            <div>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.backgroundContainer}
                wrap="nowrap"
              >
                <Grid item>
                  <div className={classes.cardItem}>
                    <ContentCard
                      title={getStartedTitle}
                      description={getStartedDescription}
                      imageUrl={
                        getStartedImage.fields.file.url || getStartedImageUrl
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
        <div className={classes.contentfulLogoContainer}>
          <ContentfulLogo type="dark-background-monochrome" />
        </div>
      </Container>
    </div>
  )
}

export default LandingPage
