import React, { FC } from 'react'
import { Asset } from 'contentful'
import downloadAsset from '../utils/downloadAsset'
import Button from './Button'
import { makeStyles } from '@mui/styles'
import { Download as DownloadIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'

const useStyles = makeStyles({
  assetBoxInner: {
    height: '100%',
    maxWidth: '100%',
    padding: 32,
    width: '100%',
  },
  innerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

interface AssetDownloadButtonProps {
  asset: Asset
}

const AssetDownloadButton: FC<AssetDownloadButtonProps> = ({ asset }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6}>
      <Button
        color="primary"
        variant="contained"
        component="a"
        className={classes.assetBoxInner}
        onClick={(e: any) => {
          e.preventDefault()
          downloadAsset(asset.fields.file)
        }}
        href={asset.fields.file.url}
        download={asset.fields.file.fileName}
        target="_blank"
        endIcon={<DownloadIcon />}
      >
        {asset.fields.title}
      </Button>
    </Grid>
  )
}

export default AssetDownloadButton
