import React, { FC, useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { Formik, Form } from 'formik'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import TextField from './TextField'
import FullScreenModal from './FullScreenModal'
import { isEmpty } from 'lodash'
import { object, string } from 'yup'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { MarkdownModalTitleSection } from './MarkdownTypography'
import { accountsId } from '../contentful/ids'
import { IAccountsFields } from '../types/generated/contentful'
import { PARTNER_ROLE } from '../utils/roles'
import { createClientFriendlyErrorHandler } from '../apolloClient'
import { useSendResetPasswordUrlMutation } from '@tmw/api-client'
import { rootPath } from '../paths'
import FormMessage from './FormMessage'
import Button from './Button'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 50,
  },
  button: {
    marginTop: 142,
    height: 56,
    width: '80%',
    maxWidth: 344,
    letterSpacing: '1.25px',
  },
})

const makeValidationSchema = (t: TFunction) =>
  object().shape({
    username: string()
      .email(t<string>('validations.email.invalid'))
      .required(t<string>('validations.email.required')),
  })

interface IFormValues {
  username: string
  role: string
}

const ForgotPassword: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [sendResetPasswordUrl] = useSendResetPasswordUrlMutation()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const entry = useContentfulEntry<IAccountsFields>(accountsId)

  if (!entry) return null
  const {
    fields: { forgotPasswordTitle: title },
  } = entry

  const initialValues = {
    username: '',
    role: PARTNER_ROLE,
  }

  const onSubmit = async (values: IFormValues) => {
    const errorHandler = createClientFriendlyErrorHandler(setErrorMessage)

    try {
      await sendResetPasswordUrl({ variables: values })
      setSuccessMessage(t<string>('forms.resetPassword.linkSend'))
      setTimeout(() => navigate(rootPath), 3000)
    } catch (error) {
      errorHandler(error)
    }
  }

  return (
    <FullScreenModal size="small">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={makeValidationSchema(t)}
      >
        {({ errors }) => {
          return (
            <Form>
              <div className={classes.container}>
                <MarkdownModalTitleSection
                  source={title}
                  className={classes.title}
                />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label={t('forms.forgotPassword.fields.email')}
                      name="username"
                      fullWidth
                      variant="filled"
                    />
                  </Grid>
                </Grid>
                <FormMessage type="error" message={errorMessage} />
                <FormMessage type="success" message={successMessage} />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isEmpty(errors)}
                  className={classes.button}
                >
                  {t('forms.forgotPassword.buttons.submit')}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </FullScreenModal>
  )
}

export default ForgotPassword
