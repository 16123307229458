import React, { FC, useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { Formik, Form } from 'formik'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import FullScreenModal from './FullScreenModal'
import { isEmpty } from 'lodash'
import { object, string, ref } from 'yup'
import { MarkdownModalTitleSection } from './MarkdownTypography'
import { passwordValidator, PASSWORD_REGEX } from '../utils/validators'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { accountsId } from '../contentful/ids'
import { IAccountsFields } from '../types/generated/contentful'
import { useResetPasswordMutation } from '@tmw/api-client'
import { createClientFriendlyErrorHandler } from '../apolloClient'
import { PasswordRules, PasswordRule } from './PasswordRules'
import { rootPath } from '../paths'
import FormMessage from './FormMessage'
import Button from './Button'
import PasswordTextField from './PasswordTextField'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 50,
  },
  button: {
    marginTop: 80,
    height: 56,
    width: '80%',
    maxWidth: 344,
  },
  grid: {
    width: 'calc(100% + 60px)',
    margin: -12,
  },
  gridItem: {
    padding: 12,
    margin: 0,
    boxSizing: 'border-box',
  },
})

const makeValidationSchema = (t: TFunction) =>
  object().shape({
    password: passwordValidator(t).required(
      t<string>('validations.password.required')
    ),
    confirmPassword: string()
      .required(t<string>('validations.confirmPassword.required'))
      .oneOf([ref('password')], t<string>('validations.confirmPassword.match')),
  })

interface IFormValues {
  token: string
  userId: number
  password: string
  confirmPassword: string
}

interface ResetPasswordProps extends RouteComponentProps {
  token?: string
  userId?: string
}

const ResetPassword: FC<ResetPasswordProps> = ({ token = '', userId = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [resetPassword] = useResetPasswordMutation()
  const [isValidPassword, setPasswordValid] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const entry = useContentfulEntry<IAccountsFields>(accountsId)

  if (!entry) return null
  const {
    fields: { resetPasswordTitle: title },
  } = entry

  const initialValues = {
    token,
    userId: parseInt(userId),
    password: '',
    confirmPassword: '',
  }

  const onSubmit = async (values: IFormValues) => {
    const errorHandler = createClientFriendlyErrorHandler(setErrorMessage)

    try {
      await resetPassword({ variables: values })
      navigate(rootPath)
    } catch (error) {
      errorHandler(error)
    }
  }

  const validatePassword = (password: string) => {
    if (password === '') {
      setPasswordValid(false)
    } else if (password.length < 8) {
      setPasswordValid(false)
    } else if (!password.match(PASSWORD_REGEX)) {
      setPasswordValid(false)
    } else {
      setPasswordValid(true)
    }
  }

  return (
    <FullScreenModal size="small">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={makeValidationSchema(t)}
      >
        {({ errors, isValid }) => {
          return (
            <Form>
              <div className={classes.container}>
                <MarkdownModalTitleSection
                  source={title}
                  className={classes.title}
                />
                <Grid container className={classes.grid}>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PasswordTextField
                      label={t<string>('forms.resetPassword.fields.password')}
                      name="password"
                      variant="filled"
                      isValidPassword={isValidPassword}
                      validatePassword={validatePassword}
                      helperText={t<string>(
                        'validations.password.validPassword'
                      )}
                    />
                  </Grid>{' '}
                  <Grid item xs={12} className={classes.gridItem}>
                    <PasswordTextField
                      label={t('forms.resetPassword.fields.confirmPassword')}
                      name="confirmPassword"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <PasswordRules>
                      <PasswordRule>
                        {t<string>('validations.password.length')}
                      </PasswordRule>
                      <PasswordRule>
                        {t<string>('validations.password.upperCase')}
                      </PasswordRule>
                      <PasswordRule>
                        {t<string>('validations.password.number')}
                      </PasswordRule>
                      <PasswordRule>
                        {t<string>('validations.password.specialCharacter')}
                      </PasswordRule>
                    </PasswordRules>
                  </Grid>
                </Grid>
                <FormMessage type="error" message={errorMessage} />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValid || !isEmpty(errors)}
                  className={classes.button}
                >
                  {t('forms.resetPassword.buttons.submit')}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </FullScreenModal>
  )
}

export default ResetPassword
