import React, { FC, useState } from 'react'
import { RouteComponentProps, Link, navigate } from '@reach/router'
import { Formik, Form } from 'formik'
import { object, string, ref } from 'yup'
import {
    stateValidator,
    zipCodeValidator,
    passwordValidator,
    translateErrorMessage,
    PASSWORD_REGEX,
} from '../../utils/validators'
import { PasswordRules, PasswordRule } from '../PasswordRules'
import { TFunction } from 'i18next'
import { useTranslation, Trans } from 'react-i18next'
import TextField from '../TextField'
import colors from '../../theme/colors'
import FullScreenModal from '../FullScreenModal'
import { isEmpty, omit, concat } from 'lodash'
import { signInPath, rootPath } from '../../paths'
import { accountsId } from '../../contentful/ids'
import useContentfulEntry from '../../contentful/useContentfulEntry'
import { IAccountsFields } from '../../types/generated/contentful'
import { createClientFriendlyErrorHandler } from '../../apolloClient'
import { useSignUpPartnerMutation } from '@tmw/api-client'
import Select from '../Select'
import MarkdownTypography from '../MarkdownTypography'
import { allStates } from '../../utils/states'
import FormMessage from '../FormMessage'
import Button from '../Button'
import PasswordTextField from '../PasswordTextField'
import { useCurrentUser } from '../../context/currentUser'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'

const useStyles = makeStyles({
    signIn: {
        color: colors.black.disabled,
    },
    signInLink: {
        color: colors.blue.primary,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: colors.blue.primary,
        marginBottom: 36,
    },
    formTittle: {
        color: colors.black.primary,
        marginTop: 24,
    },
    button: {
        marginTop: 64,
        height: 56,
        width: '80%',
        maxWidth: 344,
        letterSpacing: '1.25px',
    },
    commentsLabel: {
        marginBottom: 10,
    },
    padLeft: {
        paddingLeft: 12,
    },
    contactFormTextArea: {
        width: '100%',
    },
})

const makeValidationSchema = (t: TFunction) =>
    object().shape({
        name: string().required(t<string>('validations.name.required')),
        email: string()
            .email(t<string>('validations.email.invalid'))
            .required(t<string>('validations.email.required')),
        jobTitle: string().required(t<string>('validations.jobTitle.required')),
        organizationName: string().required(
            t<string>('validations.organizationName.required')
        ),
        organizationAddress: string().required(
            t<string>('validations.organizationAddress.required')
        ),
        organizationState: stateValidator(t).required(
            t<string>('validations.state.required')
        ),
        organizationCity: string().required(t<string>('validations.city.required')),
        organizationPostalCode: zipCodeValidator(t).required(
            t<string>('validations.zipCode.required')
        ),
        numberOfFamiliesServed: string().required(
            t<string>('validations.numberOfFamiliesServed.required')
        ),
        heardFrom: string().required(t<string>('validations.heardFrom.required')),
        existingPartnerOrganization: string().required(
            t<string>('validations.existingPartnerOrganization.required')
        ),
        password: passwordValidator(t).required(
            t<string>('validations.password.required')
        ),
        confirmPassword: string()
            .required(t<string>('validations.confirmPassword.required'))
            .oneOf([ref('password')], t<string>('validations.confirmPassword.match')),
    })

const SignIn: FC = () => {
    const classes = useStyles()

    return (
        <Typography variant="body1" className={classes.signIn}>
            <Trans i18nKey="forms.register.buttons.signIn">
                <Link to={signInPath} className={classes.signInLink} />
            </Trans>
        </Typography>
    )
}

interface Option {
    name: string
    value: string
}

const emptyOption = { name: '', value: '' }

const makeOptionsFromValues = (values: Array<string>): Array<Option> =>
    concat(
        [emptyOption],
        values.map(value => ({ name: value, value }))
    )

const makeFamiliesServedOptions = (t: TFunction) =>
    makeOptionsFromValues([
        t('forms.register.fields.numberOfFamiliesServed.options.0to50'),
        t('forms.register.fields.numberOfFamiliesServed.options.51to200'),
        t('forms.register.fields.numberOfFamiliesServed.options.201to1000'),
        t('forms.register.fields.numberOfFamiliesServed.options.1000+'),
    ])

const makeExistingOrganizationOptions = (t: TFunction) =>
    makeOptionsFromValues([
        t('forms.register.fields.existingPartnerOrganization.options.yes'),
        t('forms.register.fields.existingPartnerOrganization.options.no'),
        t('forms.register.fields.existingPartnerOrganization.options.dontKnow'),
    ])

const stateOptions = makeOptionsFromValues(allStates)

const renderOption = ({ name, value }: Option) => (
    <option value={value} key={value}>
        {name}
    </option>
)

interface IFormValues {
    name: string
    email: string
    jobTitle: string
    organizationName: string
    organizationAddress: string
    organizationState: string
    organizationCity: string
    organizationPostalCode: string
    numberOfFamiliesServed: string
    heardFrom: string
    existingPartnerOrganization: string
    comments: string
    password: string
}

const RegisterForm: FC<RouteComponentProps> = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [signUp] = useSignUpPartnerMutation()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isValidPassword, setPasswordValid] = useState<boolean>(false)
    const entry = useContentfulEntry<IAccountsFields>(accountsId)
    const { setCurrentUser } = useCurrentUser()

    if (!entry) return null
    const {
        fields: { registerTitle: title },
    } = entry

    const familiesServedOptions = makeFamiliesServedOptions(t)
    const existingPartnerOrganizationOptions = makeExistingOrganizationOptions(t)

    const initialValues = {
        name: '',
        email: '',
        jobTitle: '',
        organizationName: '',
        organizationAddress: '',
        organizationState: '',
        organizationCity: '',
        organizationPostalCode: '',
        numberOfFamiliesServed: '',
        heardFrom: '',
        existingPartnerOrganization: '',
        comments: '',
        password: '',
        confirmPassword: '',
    }

    const validatePassword = (password: string) => {
        if (password === '') {
            setPasswordValid(false)
        } else if (password.length < 8) {
            setPasswordValid(false)
        } else if (!password.match(PASSWORD_REGEX)) {
            setPasswordValid(false)
        } else {
            setPasswordValid(true)
        }
    }

    const onSubmit = async (values: IFormValues) => {
        const errorHandler = createClientFriendlyErrorHandler(setErrorMessage)

        try {
            const { data } = await signUp({
                variables: {
                    signUpPartnerInput: omit(values, 'confirmPassword'),
                },
            })
            if (data) {
                const { signUpPartner: user } = data
                setCurrentUser(user)
                navigate(rootPath)
            }
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <FullScreenModal alternateOption={<SignIn />} size="large">
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={makeValidationSchema(t)}
            >
                {({ errors }) => {
                    return (
                        <Form>
                            <div className={classes.container}>
                                <Typography
                                    variant="h5"
                                    align="center"
                                    className={classes.title}
                                >
                                    {title}
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.formTittle}>
                                            {t('forms.register.groups.self')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={t('forms.register.fields.name')}
                                            name="name"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={t('forms.register.fields.jobTitle')}
                                            name="jobTitle"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('forms.register.fields.email')}
                                            name="email"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <PasswordTextField
                                            label={t('forms.register.fields.password')}
                                            name="password"
                                            variant="filled"
                                            isValidPassword={isValidPassword}
                                            validatePassword={validatePassword}
                                            helperText={t<string>(
                                                'validations.password.validPassword'
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <PasswordTextField
                                            label={t('forms.register.fields.confirmPassword')}
                                            name="confirmPassword"
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordRules>
                                            <PasswordRule>
                                                {t<string>('validations.password.length')}
                                            </PasswordRule>
                                            <PasswordRule>
                                                {t<string>('validations.password.upperCase')}
                                            </PasswordRule>
                                            <PasswordRule>
                                                {t<string>('validations.password.number')}
                                            </PasswordRule>
                                            <PasswordRule>
                                                {t<string>('validations.password.specialCharacter')}
                                            </PasswordRule>
                                        </PasswordRules>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.formTittle}>
                                            {t('forms.register.groups.organization')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('forms.register.fields.organizationName')}
                                            name="organizationName"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('forms.register.fields.organizationAddress')}
                                            name="organizationAddress"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={t('forms.register.fields.city')}
                                            name="organizationCity"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            id="organizationState"
                                            label={t('forms.register.fields.state')}
                                            name="organizationState"
                                            fullWidth
                                        >
                                            {stateOptions.map(renderOption)}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={t('forms.register.fields.zipCode')}
                                            name="organizationPostalCode"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            id="numberOfFamiliesServed"
                                            label={t(
                                                'forms.register.fields.numberOfFamiliesServed.label'
                                            )}
                                            name="numberOfFamiliesServed"
                                            fullWidth
                                        >
                                            {familiesServedOptions.map(renderOption)}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.formTittle}>
                                            {t('forms.register.groups.organizationInterest')}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} className={classes.padLeft}>
                                        <Typography>
                                            {t(`forms.register.fields.heardFrom`)}
                                        </Typography>
                                        <TextField
                                            id="heardFrom"
                                            name="heardFrom"
                                            fullWidth
                                            variant="filled"
                                        />
                                    </Grid>
                                    <Grid container item xs={12} className={classes.padLeft}>
                                        <Typography>
                                            {t(`forms.register.fields.existingPartnerOrganization.label`)}
                                        </Typography>
                                        <Select

                                            id="existingPartnerOrganization"
                                            label={t(
                                                ''
                                            )}
                                            name="existingPartnerOrganization"
                                            fullWidth
                                        >
                                            {existingPartnerOrganizationOptions.map(renderOption)}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MarkdownTypography
                                            source={t('forms.register.fields.comments')}
                                            className={classes.commentsLabel}
                                        />
                                        <TextField
                                            name="comments"
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows={6}
                                        />
                                    </Grid>
                                </Grid>
                                <FormMessage
                                    type="error"
                                    message={translateErrorMessage(
                                        t,
                                        'forms.signUp.errorMessages',
                                        errorMessage
                                    )}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!isEmpty(errors)}
                                    className={classes.button}
                                >
                                    {t('forms.register.buttons.submit')}
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </FullScreenModal>
    )
}

export default RegisterForm
