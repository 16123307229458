import React, { FC } from 'react'
import colors from '../theme/colors'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { IHeadersAndFootersFields } from '../types/generated/contentful'
import MarkdownTypography from './MarkdownTypography'
import Link from './Link'
import { Asset } from 'contentful'
import { headersAndFootersId } from '../contentful/ids'
import { makeStyles } from '@mui/styles'
import { Container, Grid, Theme, Toolbar, Typography } from '@mui/material'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  container: {
    backgroundColor: colors.blue.primary,
    justifySelf: 'flex-end',
    flex: '0 0',
    marginTop: 64,
    paddingTop: 50,
    paddingBottom: 50,
    [breakpoints.up('sm')]: {
      maxHeight: 228,
    },
  },
  copyrightContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: 128,
    '& p': {
      marginBottom: 8,
    },
  },
  logo: {
    maxWidth: 175,
  },
  wideLogo: {
    margin: '10px 0',
    maxWidth: 175,
    [breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: 50,
      marginLeft: 121,
    },
    [breakpoints.down('xs')]: {
      margin: 0,
      maxHeight: 50,
      marginLeft: 60,
      marginRight: 61,
    },
  },
  logosContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& *+*': {
      marginLeft: 50,
    },
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      '& *': {
        marginBottom: 12,
      },
    },
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginTop: 24,
      '& *+*': {
        marginLeft: 0,
        marginRight: 24,
      },
    },
  },
  text: {
    color: colors.white.primary,
    textDecoration: 'none',
  },
  toolBar: {
    height: '100%',
    minHeight: 68,
  },
}))

const Footer: FC = () => {
  const classes = useStyles()
  const entry =
    useContentfulEntry<IHeadersAndFootersFields>(headersAndFootersId)

  if (!entry) return null

  const {
    fields: { footerBody, partnerLogosFooter },
  } = entry

  return (
    <div className={classes.container}>
      <Container>
        <Toolbar disableGutters className={classes.toolBar}>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.linksContainer}>
              <MarkdownTypography
                source={footerBody}
                renderers={{
                  p: props => <Typography variant="body2" {...props} />,
                  a: props => <Link className={classes.text} {...props} />,
                }}
              />
            </Grid>
            <Grid container item xs={12} sm={6}>
              <Grid item xs={12} className={classes.logosContainer}>
                {partnerLogosFooter?.map((logo: Asset, index: number) => (
                  <img
                    className={index === 0 ? classes.wideLogo : classes.logo}
                    key={logo.sys.id}
                    ari-hidden="true"
                    alt=""
                    src={logo.fields.file.url}
                  />
                ))}
              </Grid>
              <Grid item xs={12} className={classes.copyrightContainer}>
                <Typography className={classes.text} variant="body2">
                  &copy; Copyright {new Date().getFullYear()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </div>
  )
}

export default Footer
