export const accountsId = '2I9J9AuwWQk9npzhxBM6Rx'
export const trainingId = '313UVFgqCW2U59GaZ3Ticb'
export const staffTrainingId = '2uUfFvREWKjOYRFbARRwwO'
export const headersAndFootersId = 'DtJjjSm6UaRds7l7LPPNd'
export const landingHomePageId = '371WDUKg6a2hODjS6BayIs'
export const getStartedContentId = '5I7aNyWT43SeZSj08h8IwN'
export const whoContentId = '4bkB5JlB5B1vwZjKJAOIjx'
export const whatContentId = '5ZhysoACXge6fbn7WAQByW'
export const howContentId = '7gnA2VPwvYrwbgG50AqZ9l'
export const contactPageId = '4oq5bQw5Y5A5dbAI920iJf'
export const rolloutPageId = '4hanpGk5ppjFGJ79TTgPig'
export const ambassadorsId = '1shm8FVxoGL5bY0IOycaHn'
export const learnMoreId = '2gYDCdzNxy9apOmGRToNT0'
