import React, { FC } from 'react'
import colors from '../theme/colors'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { IHeadersAndFootersFields } from '../types/generated/contentful'
import { Asset } from 'contentful'
import { headersAndFootersId } from '../contentful/ids'
import { makeStyles } from '@mui/styles'
import {
  Theme,
  AppBar as MUIAppBar,
  Container,
  Toolbar,
  Typography,
} from '@mui/material'

const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
  bar: {
    backgroundColor: colors.white.primary,
    height: 100,
  },
  innerContainer: {
    height: '100%',
  },
  logo: {
    maxWidth: 150,
    marginRight: 32,
  },
  menu: {
    color: colors.black.medium,
    display: 'block',
    marginRight: 24,
    visibility: 'visible',
    [breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  menuIcon: {
    width: 32,
    height: 'auto',
  },
  tabs: {
    display: 'none',
    visibility: 'hidden',
    [breakpoints.up('md')]: {
      display: 'block',
      visibility: 'visible',
    },
  },
  verticalTabs: {
    borderRight: `1px solid ${palette.divider}`,
  },
  toolBar: {
    height: '100%',
    justifyContent: 'space-between',
  },
  toolBarSection: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& + &': {
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  siteTitle: {
    color: colors.purple.primary,
    display: 'flex',
    marginLeft: 24,
    marginRight: 44,
  },
  siteLogo: {
    height: '100%',
  },
}))

interface AppBarProps {
  elevation?: number
}

const AppBar: FC<AppBarProps> = ({ elevation = 0 }) => {
  const classes = useStyles()
  const entry =
    useContentfulEntry<IHeadersAndFootersFields>(headersAndFootersId)

  if (!entry) return null

  const {
    fields: { siteTitle, siteLogo, partnerLogosAppBar },
  } = entry

  return (
    <MUIAppBar elevation={elevation} className={classes.bar}>
      <Container className={classes.innerContainer}>
        <Toolbar disableGutters className={classes.toolBar}>
          <div className={classes.toolBarSection}>
            <img
              aria-hidden="true"
              alt=""
              src={siteLogo.fields.file.url}
              className={classes.siteLogo}
            />
            <Typography
              variant="h5"
              component="span"
              className={classes.siteTitle}
            >
              {siteTitle}
            </Typography>
          </div>
          <div className={classes.toolBarSection}>
            {partnerLogosAppBar?.map(
              (logo: Asset, index: number) =>
                logo?.fields?.file && (
                  <img
                    className={classes.logo}
                    key={index}
                    ari-hidden="true"
                    alt=""
                    src={logo.fields.file.url}
                  />
                )
            )}
          </div>
        </Toolbar>
      </Container>
    </MUIAppBar>
  )
}

export default AppBar
