import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import Logger from 'js-logger'
import { flow } from 'lodash'
import { PlatformApiLink } from '@tmw/api-client'
import { onError } from '@apollo/client/link/error'

const logMiddleware = new ApolloLink((operation: any, forward: any) => {
  Logger.info(`GraphQL Request: ${operation.operationName} `, {
    variables: operation.variables,
  })

  return forward(operation)
})

const isAuthenticationTokenInvalid = (e: any) =>
  e.message.includes(
    'The current user is not authorized to access this resource'
  )

const clientError = (errors: any) =>
  errors.every((e: any) => {
    const code = parseInt(e.extensions && e.extensions.code)
    return code >= 400 && code < 500
  })

const apolloClient = new ApolloClient({
  link: (ApolloLink as any).from([
    onError(({ graphQLErrors, networkError }: any) => {
      if (networkError) {
        Logger.error('Network Error', networkError)
      } else if (graphQLErrors.some(isAuthenticationTokenInvalid)) {
        Logger.warn('Auth token no longer valid so logging out...')
        authErrorHandler()
      } else if (clientError(graphQLErrors)) {
        Logger.error('Client Error', graphQLErrors)
      } else {
        Logger.error('GraphQL Error', graphQLErrors)
      }
    }),
    logMiddleware,
    PlatformApiLink({
      uri: process.env.REACT_APP_PLATFORM_API_ENDPOINT,
    }),
  ]),
  cache: new InMemoryCache(),
})

let authErrorHandler = () => Promise.resolve()

export const onGraphqlAuthenticationError = (fn: () => Promise<void>) => {
  authErrorHandler = fn
}

export const clientFriendlyErrors = ({ graphQLErrors }: any) => {
  return graphQLErrors.map(({ message }: any) => message).join('\n')
}

export const createClientFriendlyErrorHandler = (handler: any) => {
  return (error: any) => {
    flow(clientFriendlyErrors, handler)(error)
  }
}

export default apolloClient
