import client from './client'
import { BackendModule } from 'i18next'
import { ILocalizationsFields } from '../types/generated/contentful'

export const localizationContentId = '5Wu4iXC6wMN40CHlZVTZCV'

const plugin: BackendModule = {
  type: 'backend',
  init: () => {},
  create: () => {},
  read: (language, _namespace, callback) => {
    client
      .getEntry<ILocalizationsFields>(localizationContentId, {
        locale: language,
      })
      .then(({ fields }) => {
        callback(null, fields)
      })
  },
}

export default plugin
