const colors: any = {
  blue: {
    900: '#093A45',
    800: '#0B4957',
    700: '#0D5868',
    variant: '#0D5868',
    600: '#12758A',
    500: '#1692AD',
    primary: '#1692AD',
    400: '#4AB8D0',
    300: '#7ED0E1',
    200: '#AEE3EF',
    100: '#DCF4FA',
    50: '#EBF7F9',
  },
  purple: {
    500: '#492B7C',
    primary: '#492B7C',
    400: '#6D5596',
    300: '#9280B0',
    200: '#B6AACB',
    100: '#DBD5E5',
  },
  success: {
    dark: '#35D391',
    light: '#5BECB8',
  },
  warning: {
    dark: '#FF6634',
    light: '#FFCD00',
  },
  error: {
    primary: '#f44336',
  },
  orange: {
    light: '#FFF5E5',
    500: '#ff6634',
    300: '#EFA772',
    200: '#ffc2ae',
    primary: '#ff6634',
  },
  green: {
    500: '#4B8009',
    primary: '#4B8009',
    400: '#6F993A',
    300: '#93B36B',
    200: '#B7CC9D',
  },
  yellow: {
    400: '#EECA36',
    100: '#FBF2CD',
  },
  white: {
    primary: '#ffffff',
    medium: '#E5E5E5',
  },
  black: {
    primary: '#000000',
    high: 'rgba(0, 0, 0, 0.87)',
    medium: 'rgba(0, 0, 0, 0.6)',
    disabled: '#ADADAD',
    inactive: 'rgba(0, 0, 0, 0.54)',
    filledInput: 'rgba(0, 0, 0, 0.09)'
  },
  gray: {
    dark: 'rgba(0, 0, 0, 0.24)',
    light: 'rgba(0, 0, 0, 0.04)',
    divider: '#E5E5E5',
    primary: '#F5F5F5',
    disabled: '#D7D5D5',
  },
}

colors.primary = colors.blue

export default colors
