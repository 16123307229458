import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { makeStyles } from '@mui/styles'
import useContentfulEntry from '../contentful/useContentfulEntry'
import {
  MarkdownPageBodySection,
  MarkdownPageTitleSection,
} from './MarkdownTypography'
import ProductSection from './ProductSection'
import { IResourcesPageFields } from '../types/generated/contentful'
import Button from './Button'
import { Container, Theme } from '@mui/material'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonLink: {
    textDecoration: 'none',
  },
  button: {
    marginTop: 24,
    marginBottom: 24,
    minWidth: 344,
    padding: '20px 8px',
  },
}))

const Resources: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const entry = useContentfulEntry<IResourcesPageFields>(
    '73U2CMXMJu573Fh4eJnodN'
  )

  if (!entry) return null
  const {
    fields: { title, body, productSections, shopLinkText, shopLinkUrl },
  } = entry

  return (
    <Container>
      <header>
        <MarkdownPageTitleSection source={title} />
      </header>
      {body && <MarkdownPageBodySection source={body} />}
      {shopLinkText && (
        <div className={classes.buttonContainer}>
          <a
            className={classes.buttonLink}
            href={shopLinkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {shopLinkText}
            </Button>
          </a>
        </div>
      )}
      {productSections?.map(section => (
        <ProductSection key={section.sys.id} entry={section} />
      ))}
    </Container>
  )
}

export default Resources
