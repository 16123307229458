import React, { FC, FormEvent, useState } from 'react'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import { TFunction } from 'i18next'
import TextField from '../TextField'
import { useTranslation } from 'react-i18next'
import { pick } from 'lodash'
import { makeStyles } from '@mui/styles'
import { useNotifyPartnerActivityMutation } from '@tmw/api-client'
import useContentfulEntry from '../../contentful/useContentfulEntry'
import { useCurrentUser } from '../../context/currentUser'
import FormMessage from '../FormMessage'
import { navigate } from '@reach/router'
import Button from '../Button'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'

let styles = {
  button: {
    marginTop: 64,
    marginBottom: 64,
    height: 56,
    width: '80%',
    maxWidth: 344,
  },
  contactFormInput: {
    width: '100%',
  },
  contactFormInputPadLeft: {
    width: '100%',
  },
  contactFormTextArea: {
    width: '100%',
  },
  form: {
    width: '100%',
  },
  gridJustifyLeft: {
    justify: 'left',
  },
  gridJustifyCenter: {
    justify: 'center',
  },
  padLeft: {
    paddingLeft: 12,
  },
}

interface CommentFieldType {
  name: string
  label: string
  required?: string
}

interface FormData {
  fields: { field: string; data: any }
  commentFields: CommentFieldType[]
  errorMessage: string
  successMessage: string
  redirectTo?: string
}

const commentFieldPrefix = 'comment-'

const idFromCommentField = (field: CommentFieldType): string => {
  return `${commentFieldPrefix}${field.name}`
}

const dynamicCommentsFields = (commentFields: CommentFieldType[]) => {
  return commentFields.reduce((accumulator: any, key) => {
    if (key.required) {
      accumulator[idFromCommentField(key)] = string().required(key.required)
    }
    return accumulator
  }, {})
}

const requiredFormFields = (t: TFunction, fields: FormData['fields']) => {
  const validators = {
    email: string()
      .email(t<string>('forms.ambassador.fields.email.invalid'))
      .required(t<string>(`forms.ambassador.fields.email.required`)),
    name: string().required(t<string>(`forms.ambassador.fields.name.required`)),
    organization: string().required(
      t<string>(`forms.ambassador.fields.organization.required`)
    ),
  }

  return pick(validators, Object.keys(fields))
}

const createValidationSchema = (
  t: TFunction,
  { commentFields, fields }: FormData
) => {
  return object().shape({
    ...requiredFormFields(t, fields),
    ...dynamicCommentsFields(commentFields),
  })
}

const useStyles = makeStyles({ ...styles })

export const AmbassadorForm: FC = () => {
  const classes = useStyles()
  const formType = 'ambassador'

  const { t } = useTranslation()

  const [notifyPartnerActivity] = useNotifyPartnerActivityMutation()

  const [commentData, setCommentData] = useState<{ [key: string]: string }>({})
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [successMessage, setSuccessMessage] = useState<string | undefined>()

  const { currentUser } = useCurrentUser()

  const entry = useContentfulEntry<any>('5Wu4iXC6wMN40CHlZVTZCV')

  if (!entry) return null

  const formData: FormData = entry?.fields['forms'][formType]

  interface FormVariables {
    userId?: string | null
    name?: string | null
    email?: string | null
    organization?: string | null
    comment?: string | null
    [key: string]: any
  }

  const initialValues: FormVariables = {
    name: '',
    email: '',
    organization: '',
  }

  const commentFields: CommentFieldType[] = formData
    ? formData.commentFields
    : []

  const initialValuesWithComments = commentFields.reduce((prev, curr) => {
    prev[idFromCommentField(curr)] = ''

    return prev
  }, initialValues)

  const updateCommentData = (event: FormEvent<HTMLFormElement>) => {
    const { name, value } = event.target as unknown as {
      name: string
      value: string
    }

    setCommentData({
      ...commentData,
      [name]: value,
    })
  }

  const commentDataToString = Object.entries(commentData)
    .filter(([key, val]) => {
      return key.includes(commentFieldPrefix)
    })
    .map(([key, value], _idx, arr) => {
      if (arr.length === 1) {
        return value
      }
      return `${key.replace(commentFieldPrefix, '')}: ${value}`
    })
    .join('\n')

  const resetFormAndComments = (
    resetForm: () => void,
    setFieldValue: (key: string, val: string) => void
  ) => {
    resetForm()

    commentFields
      .map(field => {
        return idFromCommentField(field)
      })
      .forEach(key => {
        setFieldValue(key, '')
      })
  }

  return (
    <Formik
      initialValues={initialValuesWithComments}
      onSubmit={async (
        { name, email, organization }: FormVariables,
        { setSubmitting, resetForm, setFieldValue }: any
      ) => {
        setSubmitting(true)

        try {
          await notifyPartnerActivity({
            variables: {
              userId: currentUser?.id ? String(currentUser?.id) : null, // id is an int, but an String(undefined int) === 'undefined'
              form: formType,
              name,
              email,
              organization,
              comment: commentDataToString,
            },
          })

          setSuccessMessage(formData.successMessage)
          resetFormAndComments(resetForm, setFieldValue)
          setSubmitting(false)

          if (formData.redirectTo) {
            navigate(formData.redirectTo)
          }
        } catch (error) {
          setErrorMessage(formData.errorMessage)
          setSubmitting(false)
        }
      }}
      validationSchema={createValidationSchema(t, formData)}
    >
      {({ isValid }: any) => {
        return (
          <Form className={classes.form} onChange={updateCommentData}>
            <Grid container item xs={12}>
              <Grid container item xs={4}>
                <Typography className={classes.padLeft}>
                  {t(`forms.${formType}.fields.name.label`)}
                </Typography>
                <TextField
                  id="name"
                  name="name"
                  hiddenLabel
                  variant="filled"
                  classes={{
                    root: classes.contactFormInput,
                  }}
                />
              </Grid>
              <Grid container item xs={4} className={classes.padLeft}>
                <Typography className={classes.padLeft}>
                  {t(`forms.${formType}.fields.email.label`)}
                </Typography>
                <TextField
                  hiddenLabel
                  id="email"
                  name="email"
                  variant="filled"
                  className={classes.contactFormTextArea}
                />
              </Grid>
              <Grid container item xs={4} className={classes.padLeft}>
                <Typography className={classes.padLeft}>
                  {t(`forms.${formType}.fields.organization.label`)}
                </Typography>
                <TextField
                  hiddenLabel
                  id="organization"
                  name="organization"
                  variant="filled"
                  className={classes.contactFormTextArea}
                />
              </Grid>
              {commentFields.map(field => {
                const key = idFromCommentField(field)

                return (
                  <Grid
                    container
                    item
                    xs={12}
                    style={{ marginTop: 20 }}
                    key={key}
                  >
                    <Typography className={classes.padLeft}>
                      {field.label}
                    </Typography>
                    <TextField
                      hiddenLabel
                      id={key}
                      name={key}
                      variant="filled"
                      multiline
                      rows="6"
                      className={classes.contactFormTextArea}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <FormMessage type="error" message={errorMessage} />
              <FormMessage type="success" message={successMessage} />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Button
                className={classes.button}
                color="primary"
                disabled={!isValid}
                variant="contained"
                type="submit"
              >
                {t(`forms.${formType}.buttons.submit`)}
              </Button>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
