import React, {
  createContext,
  FC,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react'
import { usePartnerMeQuery } from '@tmw/api-client'
import Loading from '../components/Loading'
import apolloClient from '../apolloClient'
import { PARTNER_ROLE } from '../utils/roles'

export interface User {
  id: number
}

export interface ICurrentUserContextValue {
  currentUser: User | undefined
  setCurrentUser: (user: User) => void
  resetCurrentUser: () => void
  isSignedIn: boolean
}

export const CurrentUserContext = createContext<ICurrentUserContextValue>({
  currentUser: undefined,
  setCurrentUser: () => {},
  resetCurrentUser: () => {},
  isSignedIn: false,
})

type Props = {
  children: ReactNode
}

export const CurrentUserProvider: FC<Props> = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState<User | undefined>()
  const resetCurrentUser = () => {
    setCurrentUser(undefined)
    apolloClient.clearStore()
  }

  const { data, error } = usePartnerMeQuery()

  useEffect(() => {
    if (data?.currentUser) {
      if (data?.currentUser.role !== PARTNER_ROLE) {
        resetCurrentUser()
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setCurrentUser(data.currentUser)
      }
    } else if (error) {
      setIsLoading(false)
    }
  }, [data, error])

  const isSignedIn = !!currentUser

  return isLoading ? (
    <Loading />
  ) : (
    <CurrentUserContext.Provider
      value={{ currentUser, setCurrentUser, resetCurrentUser, isSignedIn }}
      {...props}
    />
  )
}

export const useCurrentUser = () => useContext(CurrentUserContext)
