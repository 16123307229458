import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { ITrainingPageFields } from '../types/generated/contentful'
import {
  MarkdownPageBodySection,
  MarkdownPageTitleSection,
} from './MarkdownTypography'
import AssetDownloadButton from './AssetDownloadButton'
import CalloutSection from './CalloutSection'
import colors from '../theme/colors'
import { trainingId } from '../contentful/ids'
import { makeStyles } from '@mui/styles'
import { Container, Grid, Typography } from '@mui/material'

const useStyles = makeStyles(({ breakpoints }) => ({
  assetTitle: {
    color: colors.white.primary,
    textTransform: 'uppercase',
  },
  assetBoxInner: {
    backgroundColor: colors.blue[500],
    height: '100%',
    padding: 32,
    width: '100%',
  },
  sectionTitle: {
    marginBottom: 32,
    marginTop: 64,
  },
}))

const Training: FC<RouteComponentProps> = () => {
  const entry = useContentfulEntry<ITrainingPageFields>(trainingId)

  const classes = useStyles()

  if (!entry) return null
  const {
    fields: {
      title,
      body,
      assetsSectionTitle,
      assets,
      calloutSectionTitle,
      calloutSections,
    },
  } = entry

  return (
    <Container>
      <header>
        <MarkdownPageTitleSection source={title} />
      </header>
      <MarkdownPageBodySection source={body} />
      <Typography component="h2" variant="h5" className={classes.sectionTitle}>
        {assetsSectionTitle}
      </Typography>
      <Grid container spacing={3} wrap="wrap">
        {assets &&
          assets.map(asset => (
            <AssetDownloadButton key={asset.sys.id} asset={asset} />
          ))}
      </Grid>
      <Typography component="h2" variant="h5" className={classes.sectionTitle}>
        {calloutSectionTitle}
      </Typography>
      <Grid container spacing={3}>
        {calloutSections &&
          calloutSections.map(callout => (
            <CalloutSection key={callout.sys.id} entry={callout} itemSize={4} />
          ))}
      </Grid>
    </Container>
  )
}

export default Training
