const downloadAsset = async ({
  url,
  fileName,
}: {
  url: string
  fileName: string
}) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const blobUrl = URL.createObjectURL(blob)
  const hiddenLink = document.createElement('a')

  hiddenLink.setAttribute('href', blobUrl)
  hiddenLink.setAttribute('download', fileName)
  document.body.appendChild(hiddenLink)
  hiddenLink.click()
  document.body.removeChild(hiddenLink)
}

export default downloadAsset
