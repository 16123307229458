import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { IStaffTrainingPageFields } from '../types/generated/contentful'
import {
  MarkdownPageTitleSection,
  MarkdownPageBodySection,
} from './MarkdownTypography'
import CalloutSection from './CalloutSection'
import { staffTrainingId } from '../contentful/ids'
import { ContactForm } from './ContactForm'
import FAQEntry from './FAQEntry'
import { makeStyles } from '@mui/styles'
import { Container, Grid, Typography } from '@mui/material'

const useStyles = makeStyles({
  container: {
    paddingBottom: 88,
  },
  sectionTitle: {
    marginBottom: 32,
    marginTop: 64,
  },
  faqEntries: {
    marginTop: 39,
  },
  contact: {
    marginTop: 62,
  },
  contactBody: {
    marginBottom: 32,
  },
})

const StaffTraining: FC<RouteComponentProps> = () => {
  const entry = useContentfulEntry<IStaffTrainingPageFields>(staffTrainingId)

  const classes = useStyles()

  if (!entry) return null
  const {
    fields: {
      title,
      body,
      faqSectionTitle,
      faqEntries,
      calloutSectionTitle,
      calloutSections,
      contactSectionBody,
    },
  } = entry

  return (
    <Container className={classes.container}>
      <header>
        <MarkdownPageTitleSection source={title} />
      </header>
      <MarkdownPageBodySection source={body} />
      <MarkdownPageTitleSection source={faqSectionTitle} />
      <div className={classes.faqEntries}>
        {faqEntries.map(entry => (
          <FAQEntry item={entry} key={entry.sys.id} />
        ))}
      </div>
      <Typography component="h2" variant="h5" className={classes.sectionTitle}>
        {calloutSectionTitle}
      </Typography>
      <Grid container spacing={3}>
        {calloutSections &&
          calloutSections.map(callout => (
            <CalloutSection key={callout.sys.id} entry={callout} itemSize={6} />
          ))}
      </Grid>
      <div className={classes.contact}>
        <div className={classes.contactBody}>
          <MarkdownPageBodySection source={contactSectionBody} />
        </div>
        <ContactForm formType="contact" />
      </div>
    </Container>
  )
}

export default StaffTraining
