import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import useContentfulEntry from '../../contentful/useContentfulEntry'
import { MarkdownPageTitleSection } from '../MarkdownTypography'
import { ambassadorsId } from '../../contentful/ids'
import { IAmbassadorsPageFields } from '../../types/generated/contentful'
import { Container } from '@mui/material'

const Thanks: FC<RouteComponentProps> = () => {
  const entry = useContentfulEntry<IAmbassadorsPageFields>(ambassadorsId)

  if (!entry) return null
  const {
    fields: { thanksMessage },
  } = entry

  return (
    <Container>
      <header>
        <MarkdownPageTitleSection source={thanksMessage} />
      </header>
    </Container>
  )
}

export default Thanks
