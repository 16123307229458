import { createTheme, PaletteOptions, Theme, ThemeOptions } from '@mui/material'
import colors from './colors'
import './fonts.css'

const palette: PaletteOptions = {
  primary: {
    dark: colors.blue[900],
    main: colors.blue.primary,
    light: colors.blue[300],
    contrastText: '#FFF',
  },
  background: {
    paper: '#FFF',
    default: '#FAFAFA',
  },
  divider: colors.gray.divider,
  text: {
    primary: colors.black.medium,
  },
}

const fallbackFonts =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
export const boldFontFamily = `GT-Walsheim-Pro-Bold, ${fallbackFonts}`
export const mediumFontFamily = `GT-Walsheim-Pro-Medium, ${fallbackFonts}`
export const regularFontFamily = `GT-Walsheim-Pro-Regular, ${fallbackFonts}`

export const themeObject = {
  palette,
  typography: {
    h1: {
      fontFamily: boldFontFamily,
      fontSize: '6rem',
      lineHeight: 1.25,
    },
    h2: {
      fontFamily: boldFontFamily,
      fontSize: '3.75rem',
      lineHeight: 1.33,
    },
    h3: {
      fontFamily: boldFontFamily,
      fontSize: '3rem',
      lineHeight: 1.5,
    },
    h4: {
      fontFamily: boldFontFamily,
      fontSize: '2.25rem',
      lineHeight: 1.33,
    },
    h5: {
      fontFamily: boldFontFamily,
      fontSize: '1.5rem',
      lineHeight: 1.5,
    },
    h6: {
      fontFamily: mediumFontFamily,
      fontSize: '1.25rem',
      lineHeight: 1.4,
    },
    subtitle1: {
      fontFamily: boldFontFamily,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    subtitle2: {
      fontFamily: mediumFontFamily,
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
    body1: {
      fontFamily: regularFontFamily,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: regularFontFamily,
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
    button: {
      fontFamily: mediumFontFamily,
      fontSize: '0.875rem',
      lineHeight: 1.14,
      textTransform: 'uppercase',
      maxWidth: '328px',
    },
    caption: {
      fontFamily: regularFontFamily,
      fontSize: '0.75rem',
      lineHeight: 1.33,
    },
    overline: {
      fontFamily: mediumFontFamily,
      fontSize: '0.75rem',
      lineHeight: 1.2,
      textTransform: 'uppercase',
      letterSpacing: '0.09375rem',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: colors.purple.primary,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 'initial',
          padding: '10px 8px',
          letterSpacing: '1.25px',
          '&.Mui-disabled': {
            color: colors.black.disabled,
            padding: '10px 8px',
            border: `1px solid ${colors.black.disabled}`,
            '&:hover': {
              border: `1px solid ${colors.black.disabled}`,
            },
          },
          textTransform: 'none',
        },
        text: {
          minWidth: 'auto',
          minHeight: 'auto',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        textSecondary: {
          color: colors.white.primary,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            color: colors.black.disabled,
          },
        },
        outlined: {
          padding: '10px 8px',
        },
        outlinedSecondary: {
          color: colors.white.primary,
          border: `1px solid ${colors.white.primary}`,
          '&:hover': {
            border: `1px solid ${colors.white.primary}`,
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            color: colors.black.disabled,
            border: `1px solid ${colors.black.disabled}`,
            '&:hover': {
              border: `1px solid ${colors.black.disabled}`,
            },
          },
        },
        containedSecondary: {
          backgroundColor: colors.white.primary,
          color: colors.blue.primary,
          '&:hover': {
            backgroundColor: colors.white.primary,
            '@media (hover: none)': {
              backgroundColor: colors.white.primary,
            },
          },
        },
      },
    },
    // NOTE: some colors chosen off the base palette
    MuiChip: {
      styleOverrides: {
        clickableColorPrimary: {
          backgroundColor: colors.primary[50],
          color: colors.primary.primary,
          '&:active': {
            backgroundColor: '#C5E5FC',
            color: '#004880',
          },
          '&:focus': {
            backgroundColor: colors.primary[50],
            color: colors.primary.primary,
          },
        },
        colorPrimary: {
          backgroundColor: colors.primary[50],
          color: colors.primary.primary,
        },
        root: {
          backgroundColor: colors.white.primary,
          fontSize: '0.875rem',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 260,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorInherit: {
          color: colors.black.medium,
        },
        root: {
          opacity: 0.7,
          maxWidth: 264,
          minWidth: 160,
          '&.Mui-selected': {
            color: colors.primary.main,
            opacity: 1,
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          '&.Mui-selected': {
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorSecondary: {
          backgroundColor: colors.purple[200],
        },
        colorSecondary: {
          backgroundColor: colors.purple[100],
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-wrap',
          color: colors.black.medium,
          zIndex: 0,
        },
        colorTextSecondary: {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
    // @ts-ignore
    MUIDataTable: {
      styleOverrides: {
        responsiveScroll: {
          overflowX: 'none',
          height: 'auto',
          maxHeight: 'auto',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        'spacing-xs-3': {
          '& > .MuiGrid-item': {
            padding: 12,
          },
        },
        'spacing-xs-5': {
          margin: -20,
          '& > .MuiGrid-item': {
            padding: 20,
          },
        },
        'grid-xs-12': {
          '@media (min-width: 1200px)': {
            maxWidth: '100%',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            borderBottom: `1px solid ${colors.orange[300]}`,
          },
        },
        underline: {
          borderBottom: 0,
          '&::before': {
            borderBottom: `1px solid ${colors.gray.divider}`,
          },
          '&:hover:not(.Mui-disabled)::before': {
            borderBottom: `1px solid ${colors.blue.primary}`,
          },
          '&::after': {
            borderBottomColor: colors.blue.primary,
          },
          '&.Mui-error::after': {
            borderBottomColor: colors.orange[300],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black.filledInput,
          lineHeight: '1.1876em',
          '&.Mui-error:after': {
            transform: 'scale(1) translateX(0)',
          },
        },
        input: {
          padding: '27px 12px 10px',
          height: '1.1876em',
        },
        multiline: {
          padding: '27px 12px 10px',
        },
        inputMultiline: {
          padding: 0,
        },
        inputAdornedEnd: {
          paddingRight: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.black.inactive,
          '&.Mui-focused': {
            color: colors.blue.primary,
          },
          '&.Mui-error': {
            color: colors.orange[300],
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.black.primary,
          '&.Mui-error': {
            color: colors.orange[300],
            marginLeft: 0
          },
        },
      },
    },
    MuiExpansionPanelDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
        },
      },
    },
    MuiExpansionPanelSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          '&.Mui-expanded': {
            margin: '0 0',
          },
        },
        content: {
          margin: '25px 0',
          '&.Mui-expanded': {
            margin: '25px 0',
          },
        },
      },
    },
    MuiExpansionPanel: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            '&:before': {
              opacity: 1,
            },
            margin: '0 0',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width: 1200px)': {
            maxWidth: 1280,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 12,
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.blue.primary,
          '& span': {
            color: 'rgba(255, 255, 255, 0.54)',
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontFamily: boldFontFamily,
          },
          '& h4': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        contentWrapper: {
          height: '325px',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.blue.primary,
            '&:focus': {
              backgroundColor: colors.blue.primary,
            },
          },
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          display: 'block',
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          height: '25px',
          '&.Mui-selected': {
            fontWeight: 500,
            margin: '10px 0',
            height: '40px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            color: colors.blue.primary,
            background: 'none',
            fontSize: '1.5rem',
            '&:hover': {
              background: 'none',
            },
          },
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
          },
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            backgroundColor: colors.blue.primary,
            color: colors.white.primary,
            '&:focus': {
              backgroundColor: colors.blue.primary,
            },
          },
        },
      },
    },
  },
}

const theme: Theme = createTheme(themeObject as ThemeOptions)

export default theme
