"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parentFragment = exports.userInfoFragment = exports.facilitatedInterventionFragment = exports.surveyFragment = exports.videoFragment = void 0;
const graphql_tag_1 = require("graphql-tag");
exports.videoFragment = (0, graphql_tag_1.default) `
  fragment InterventionVideo on InterventionVideo {
    id
    language
    name
    url
    introHeader1
    introHeader2
    questions {
      id
      language
      type
      tmwVariable
      displaySecond
      question
      answers
      scores
      preAnswerHeader
      postAnswerText
    }
    introBackgroundImage
    fileSizeInBytes
    endOfVideoInfoText
  }
`;
exports.surveyFragment = (0, graphql_tag_1.default) `
  fragment Survey on Survey {
    id
    language
    name
    sections {
      id
      language
      title
      subtitle
      body
      questions {
        id
        language
        answers
        answerType
        question
        scores
        supportingImageTitle
        supportingImageUrl
        supportingText
        supportingVideoUrl
        title
        tmwVariable
        singleSelectDropdownLabel
        prerequisiteQuestionTmwVariable
        prerequisiteQuestionAnswerMatches
      }
    }
    surveyType
    wrapUpTitle
    wrapUpBody
    wrapUpButton
    surveyPageHeader
    surveyPageSubheader
    introBackgroundImage
  }
`;
exports.facilitatedInterventionFragment = (0, graphql_tag_1.default) `
  fragment FacilitatedIntervention on FacilitatedIntervention {
    id
    facilitatorId
    interventionSeriesId
    createdAt
    numScreensCompleted
    currentStepIndex
    stepProgress
    steps
    roomName
    parent {
      tmwId
    }
    status
    startedAt
    steps
    completedAt
    timeOfLastUserActivity
    transferredFromFacilitatorId
    isArchived
    deviceId
    result
    facilitatedInterventionModules {
      id
      interventionModuleName
      startedAt
      completedAt
      currentStepIndex
      status
      result
      steps
    }
  }
`;
exports.userInfoFragment = (0, graphql_tag_1.default) `
  fragment UserInfo on CurrentUser {
    id
    firstName
    lastName
    isSpectator
    isEducator
    eceProgress
    eceStatus
    series {
      interventionName
      siteName
      interventionSeriesId
      interventionType
      siteId
    }
    role
    userRole {
      roleId
      isSetUpPending
      role {
        code
      }
    }
    userCourseDetails{
      certificateUrl
      fileName
    }
    createdAt
    email
    phoneNumber
  }
`;
exports.parentFragment = (0, graphql_tag_1.default) `
  fragment Parent on Parent {
    allowNotifications
    childGender
    hasCompletedAnyInterventions
    firstName
    lastName
    surveyStatuses {
      surveyState
      surveyType
    }
    id
    language
    mustCompletePostSpeakSurvey
    tmwId
    childDateOfBirth
    dateOfBirth
    partnerOrganizationId
    connectionType
    connectionName
    isRCUnlocked
    didGiveECEConsent
  }
`;
