"use strict";
/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoQuestionType = exports.VerificationCodeStatus = exports.SurveyState = exports.SurveyQuestionAnswerType = exports.ShareType = exports.RequestVerificationCodeStatus = exports.ReinforcingContentType = exports.LogInStatus = exports.AppName = exports.Language = exports.InterventionResult = exports.FacilitatedInterventionStatus = void 0;
//==============================================================
// START Enums and Input Objects
//==============================================================
var FacilitatedInterventionStatus;
(function (FacilitatedInterventionStatus) {
    FacilitatedInterventionStatus["ARCHIVED"] = "ARCHIVED";
    FacilitatedInterventionStatus["COMPLETED"] = "COMPLETED";
    FacilitatedInterventionStatus["DID_NOT_OFFER"] = "DID_NOT_OFFER";
    FacilitatedInterventionStatus["FINISHED"] = "FINISHED";
    FacilitatedInterventionStatus["MODULE_COMPLETED"] = "MODULE_COMPLETED";
    FacilitatedInterventionStatus["IDLE"] = "IDLE";
    FacilitatedInterventionStatus["IN_PROGRESS"] = "IN_PROGRESS";
    FacilitatedInterventionStatus["LOCKED_OUT"] = "LOCKED_OUT";
    FacilitatedInterventionStatus["ON_HOLD"] = "ON_HOLD";
    FacilitatedInterventionStatus["OPTED_OUT"] = "OPTED_OUT";
    FacilitatedInterventionStatus["READY"] = "READY";
    FacilitatedInterventionStatus["INCOMPLETE"] = "INCOMPLETE";
})(FacilitatedInterventionStatus = exports.FacilitatedInterventionStatus || (exports.FacilitatedInterventionStatus = {}));
var InterventionResult;
(function (InterventionResult) {
    InterventionResult["COMPLETED"] = "COMPLETED";
    InterventionResult["DECLINED"] = "DECLINED";
    InterventionResult["DID_NOT_OFFER"] = "DID_NOT_OFFER";
    InterventionResult["ON_HOLD"] = "ON_HOLD";
    InterventionResult["OPTED_OUT"] = "OPTED_OUT";
    InterventionResult["UNKNOWN"] = "UNKNOWN";
    InterventionResult["INCOMPLETE"] = "INCOMPLETE";
})(InterventionResult = exports.InterventionResult || (exports.InterventionResult = {}));
var Language;
(function (Language) {
    Language["EN"] = "EN";
    Language["ES"] = "ES";
})(Language = exports.Language || (exports.Language = {}));
var AppName;
(function (AppName) {
    AppName["PARENT_APP"] = "PARENT_APP";
    AppName["PARTNER_PORTAL"] = "PARTNER_PORTAL";
    AppName["LT_INTERVENTION"] = "LT_INTERVENTION";
    AppName["INTERVENTION"] = "INTERVENTION";
})(AppName = exports.AppName || (exports.AppName = {}));
var LogInStatus;
(function (LogInStatus) {
    LogInStatus["ALLOWED"] = "ALLOWED";
    LogInStatus["SETUP_CODE_SENT"] = "SETUP_CODE_SENT";
    LogInStatus["UNKNOWN_USER"] = "UNKNOWN_USER";
    LogInStatus["UPDATE_REQUIRED_CODE_SENT"] = "UPDATE_REQUIRED_CODE_SENT";
})(LogInStatus = exports.LogInStatus || (exports.LogInStatus = {}));
var ReinforcingContentType;
(function (ReinforcingContentType) {
    ReinforcingContentType["IMAGE"] = "IMAGE";
    ReinforcingContentType["LIST"] = "LIST";
    ReinforcingContentType["Q_AND_A"] = "Q_AND_A";
    ReinforcingContentType["TAPPABLE_VIDEO"] = "TAPPABLE_VIDEO";
    ReinforcingContentType["TOUCH_TARGET"] = "TOUCH_TARGET";
    ReinforcingContentType["VIDEO"] = "VIDEO";
})(ReinforcingContentType = exports.ReinforcingContentType || (exports.ReinforcingContentType = {}));
var RequestVerificationCodeStatus;
(function (RequestVerificationCodeStatus) {
    RequestVerificationCodeStatus["CODE_SENT"] = "CODE_SENT";
    RequestVerificationCodeStatus["UNKNOWN_USER"] = "UNKNOWN_USER";
})(RequestVerificationCodeStatus = exports.RequestVerificationCodeStatus || (exports.RequestVerificationCodeStatus = {}));
var ShareType;
(function (ShareType) {
    ShareType["LESSON_VIDEO"] = "LESSON_VIDEO";
    ShareType["PARENT_APP"] = "PARENT_APP";
    ShareType["REINFORCING_CONTENT"] = "REINFORCING_CONTENT";
})(ShareType = exports.ShareType || (exports.ShareType = {}));
var SurveyQuestionAnswerType;
(function (SurveyQuestionAnswerType) {
    SurveyQuestionAnswerType["SINGLE_SELECTION_BUTTONS"] = "SINGLE_SELECTION_BUTTONS";
    SurveyQuestionAnswerType["SINGLE_SELECTION_DROPDOWN"] = "SINGLE_SELECTION_DROPDOWN";
    SurveyQuestionAnswerType["SINGLE_SELECTION_RADIOS"] = "SINGLE_SELECTION_RADIOS";
    SurveyQuestionAnswerType["CHECKBOXES"] = "CHECKBOXES";
    SurveyQuestionAnswerType["FREE_TEXT"] = "FREE_TEXT";
})(SurveyQuestionAnswerType = exports.SurveyQuestionAnswerType || (exports.SurveyQuestionAnswerType = {}));
var SurveyState;
(function (SurveyState) {
    SurveyState["COMPLETED"] = "COMPLETED";
    SurveyState["DECLINED"] = "DECLINED";
})(SurveyState = exports.SurveyState || (exports.SurveyState = {}));
var VerificationCodeStatus;
(function (VerificationCodeStatus) {
    VerificationCodeStatus["INVALID"] = "INVALID";
    VerificationCodeStatus["VALID"] = "VALID";
})(VerificationCodeStatus = exports.VerificationCodeStatus || (exports.VerificationCodeStatus = {}));
var VideoQuestionType;
(function (VideoQuestionType) {
    VideoQuestionType["SINGLE_SELECTION_BUTTONS"] = "SINGLE_SELECTION_BUTTONS";
    VideoQuestionType["SINGLE_SELECTION_RADIOS"] = "SINGLE_SELECTION_RADIOS";
})(VideoQuestionType = exports.VideoQuestionType || (exports.VideoQuestionType = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
