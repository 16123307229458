import React, { FC, useEffect, useState } from 'react'
import colors from '../theme/colors'
import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles({
  error: {
    marginTop: 20,
    color: colors.orange[300],
  },
  success: {
    marginTop: 20,
    color: colors.green.primary,
  },
})

interface FormMessageProps {
  message?: string
  type: 'success' | 'error'
}

const flashMessage = ({ message, type }: FormMessageProps) => {
  return message && type === 'success'
}

const useShow = ({ message, type }: FormMessageProps) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (flashMessage({ message, type })) {
      setShow(true)

      const timer = setTimeout(() => {
        setShow(false)
      }, 5000)

      return () => {
        clearTimeout(timer)
      }
    } else if (!isEmpty(message)) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [message, type])

  return show
}

const FormMessage: FC<FormMessageProps> = ({ message, type }) => {
  const classes = useStyles()
  const styles = classes[type]
  const show = useShow({ message, type })

  return (
    <>
      {show && (
        <Typography className={styles} variant="body1">
          {message}
        </Typography>
      )}
    </>
  )
}

export default FormMessage
