import React, { FC } from 'react'
import cx from 'classnames'
import colors from '../theme/colors'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from '@reach/router'
import { makeStyles } from '@mui/styles'
import { IconButton, Theme } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles(theme => ({
  outerContainer: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 896,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
    },
  },
  closeButton: {
    marginLeft: 'auto',
    marginBottom: 30,
    padding: 12,
    [theme.breakpoints.up(1170)]: {
      position: 'relative',
      left: 95,
    },
  },
  box: {
    [theme.breakpoints.up('md')]: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.white.medium,
      marginTop: 18,
      marginBottom: 40,
      paddingTop: 30,
      paddingLeft: 92,
      paddingRight: 92,
      paddingBottom: 66,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 40,
      paddingRight: 20,
      paddingBottom: 20,
    },
  },
  smallBox: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 528,
    },
  },
  largeBox: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 896,
    },
  },
}))

interface FullScreenModalProps extends RouteComponentProps {
  size: 'small' | 'large'
  alternateOption?: React.ReactNode
  children: React.ReactNode
}

const FullScreenModal: FC<FullScreenModalProps> = ({
  size,
  alternateOption,
  children,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onClose = () => {
    window.history.back()
  }

  const boxSizeClass = size === 'small' ? classes.smallBox : classes.largeBox

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <IconButton
          onClick={onClose}
          aria-label={t<string>('buttons.close')}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
        {alternateOption}
        <div className={cx(classes.box, boxSizeClass)}>{children}</div>
      </div>
    </div>
  )
}

export default FullScreenModal
