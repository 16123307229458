import React, { ReactNode } from 'react'
import {
  Router as ReachRouter,
  RouterProps as ReachRouterProps,
} from '@reach/router'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'

export interface RouterProps extends ReachRouterProps {
  className?: string
  children?: ReactNode
}

const useStyles = makeStyles({
  router: {
    height: '100%',
  },
})

const Router: React.FC<RouterProps> = ({ className, ...props }) => {
  const classes = useStyles()
  return (
    <ReachRouter className={classnames(classes.router, className)} {...props} />
  )
}

export default Router
