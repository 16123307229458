import { FC, ReactNode } from 'react'
import colors from '../theme/colors'
import React from 'react'
import { IHowToAccordionEntry } from '../types/generated/contentful'
import { FAQEntryButton } from './FAQEntryButton'
import { ContactForm } from './ContactForm'
import { AmbassadorForm } from './Ambassadors/Form'
import MarkdownTypography from './MarkdownTypography'
import { makeStyles } from '@mui/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

interface FAQProps {
  item: IHowToAccordionEntry
  children?: ReactNode
}

const FAQEntry: FC<FAQProps> = ({ item }: FAQProps) => {
  const useStyles = makeStyles({
    body: {
      fontSize: 16,
      '& > p': {
        marginBottom: 15,
      },
    },
    box: {
      marginTop: 10,
    },
    button: {
      marginTop: 16,
    },
    sectionTitle: {
      paddingBottom: 20,
      paddingTop: 20,
    },
    panel: {
      width: '100%',
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.gray.dark}`,
      '&::before': {
        display: 'none',
      },
    },
    title: {
      fontSize: 20,
    },
  })

  const classes = useStyles()

  return (
    <>
      {item.fields.sectionTitle && (
        <Typography
          variant="h5"
          component="h5"
          className={classes.sectionTitle}
          key={`section-title-${item.sys.id}`}
        >
          {item.fields.sectionTitle}
        </Typography>
      )}

      <Accordion className={classes.panel}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`question-1-header`}
        >
          <Typography
            align="left"
            variant="subtitle2"
            className={classes.title}
          >
            {item.fields.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownTypography
            source={item.fields.body}
            className={classes.body}
          />
        </AccordionDetails>

        <Grid item xs={12}>
          <FAQEntryButton item={item} />
          {item.fields.contactFormType &&
            (item.fields.contactFormType === 'ambassador' ? (
              <AmbassadorForm />
            ) : (
              <ContactForm formType={item.fields.contactFormType} />
            ))}
        </Grid>
      </Accordion>
    </>
  )
}

export default FAQEntry
