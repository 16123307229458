"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_USER_COURSE_DETAIL = exports.GET_ECE_COURSE_ID = exports.GET_SURVEY_BY_TYPE_WITH_CONFIG_ID = exports.GET_PARENT_ROLES = exports.GET_ALL_LANGUAGE_SERIES_CONFIGS = exports.GET_LOCALE = exports.GET_POSTAL_CODE_BLACKLIST_STATUS = exports.GET_SERIES_CONFIG = exports.GET_SURVEYS_BY_SURVEY_CONFIG_ID = exports.GET_COMPLETED_SURVEY_LIST_BY_TMWID = exports.GET_SURVEY_BY_TYPE = exports.GET_SURVEY = exports.GET_PARTNER_ORGANIZATIONS = exports.GET_REINFORCING_CONTENT = exports.GET_INTERVENTION_BY_TMWID = exports.GET_INTERVENTION = exports.VALIDATE_PARENT_WITH_SITE = exports.ENGAGEMENT_SURVEYS_QUERY = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragments_1 = require("./fragments");
exports.ENGAGEMENT_SURVEYS_QUERY = (0, graphql_tag_1.default) `
  query GetFacilitatedInterventionsRequiringEngagementSurvey(
    $interventionSeriesId: Int!
  ) {
    facilitatedInterventionsRequiringEngagementSurvey(
      interventionSeriesId: $interventionSeriesId
    ) {
      ...FacilitatedIntervention
    }
  }
  ${fragments_1.facilitatedInterventionFragment}
`;
exports.VALIDATE_PARENT_WITH_SITE = (0, graphql_tag_1.default) `
  query ValidateParentWithSite($siteId: Int!, $tmwId: String!) {
    validateSite(siteId: $siteId, tmwId: $tmwId) {
      isValid
    }
  }
`;
exports.GET_INTERVENTION = (0, graphql_tag_1.default) `
  query GetFacilitatedIntervention($id: Int!) {
    getFacilitatedIntervention(id: $id) {
      ...FacilitatedIntervention
    }
  }
  ${fragments_1.facilitatedInterventionFragment}
`;
exports.GET_INTERVENTION_BY_TMWID = (0, graphql_tag_1.default) `
  query GetFacilitatedInterventionByTMWId($tmwId: String!) {
    facilitatedInterventionByTMWId(tmwId: $tmwId) {
      ...FacilitatedIntervention
    }
  }
  ${fragments_1.facilitatedInterventionFragment}
`;
exports.GET_REINFORCING_CONTENT = (0, graphql_tag_1.default) `
  query GetReinforcingContent($language: String!) {
    reinforcingContent(language: $language) {
      answerBody
      answerTitle
      body
      category
      deficient
      deficiencySurveyId
      iconTexts {
        body
        iconTitle
        iconUrl
      }
      id
      imageTitle
      imageUrl
      instructions
      intervention
      introCardBody
      introCardTitle
      lesson
      maximumShareCount
      nearby
      read
      shareCount
      slug
      title
      touchTargets {
        body
        title
        x
        y
      }
      type
      userCompletedIntervention
      videoUrl
      videoStartInstructionsTitle
      videoStartInstructionsBody
      videoTapInstructions
      videoTapSummaryTitle
      videoTapSummaryBody
    }
  }
`;
exports.GET_PARTNER_ORGANIZATIONS = (0, graphql_tag_1.default) `
  query GetPartnerOrganizations {
    partnerOrganizations {
      id
      name
    }
  }
`;
exports.GET_SURVEY = (0, graphql_tag_1.default) `
  query GetSurvey($id: String!, $language: String!) {
    survey(id: $id, language: $language) {
      ...Survey
    }
  }
  ${fragments_1.surveyFragment}
`;
exports.GET_SURVEY_BY_TYPE = (0, graphql_tag_1.default) `
  query GetSurveyByType($surveyType: String!, $language: String!) {
    surveyByType(surveyType: $surveyType, language: $language) {
      ...Survey
    }
  }
  ${fragments_1.surveyFragment}
`;
exports.GET_COMPLETED_SURVEY_LIST_BY_TMWID = (0, graphql_tag_1.default) `
  query GetCompletedSurveyListByTmwId($tmwId: String!) {
    completedSurveysByTmwId(tmwId: $tmwId) {
      surveyType
      surveyState
      surveyId
    }
  }
`;
exports.GET_SURVEYS_BY_SURVEY_CONFIG_ID = (0, graphql_tag_1.default) `
  query GetSurveysBySurveyConfigId(
    $surveyConfigId: String!
    $language: String!
  ) {
    surveysBySurveyConfigId(
      surveyConfigId: $surveyConfigId
      language: $language
    ) {
      name
      displayText
      surveyTypes
    }
  }
`;
exports.GET_SERIES_CONFIG = (0, graphql_tag_1.default) `
  query GetSeriesConfig($seriesId: Int!, $language: String!) {
    seriesConfig(seriesId: $seriesId, language: $language) {
      id
      language
      showDisclaimerDialog
      pitch {
        id
        language
        name
        slide1text
        slide2text
        slide3text
        slide4text
      }
      welcome {
        id
        language
        name
        header
        subheader
      }
      identify {
        id
        language
        name
        header
        question1Text
        question1Answer
        question2Text
        question2Answer
      }
      thanks {
        id
        language
        name
        header
        title
        canCollectPhone
        subtitle
        footer
        showSelfInvite
      }
      videos {
        ...InterventionVideo
      }
      surveys {
        ...Survey
      }
      eConsent {
        id
        language
        name
        consent
        video {
          ...InterventionVideo
        }
      }
      demographicSurvey {
        ...Survey
      }
      engagementSurvey {
        ...Survey
      }
      prioritizedVideosToCache {
        ...InterventionVideo
      }
      reconcileReasonChoices
      optedOutReasonChoices
      didNotOfferReasonChoices
      facilitatorFaq
    }
  }
  ${fragments_1.surveyFragment}
  ${fragments_1.videoFragment}
`;
exports.GET_POSTAL_CODE_BLACKLIST_STATUS = (0, graphql_tag_1.default) `
  query GetPostalCodeBlacklistStatus($postalCode: String!) {
    postalCodeBlacklistStatus(postalCode: $postalCode) {
      blacklisted
    }
  }
`;
exports.GET_LOCALE = (0, graphql_tag_1.default) `
  query GetLocales($appName: AppName!) {
    getLocales(appName: $appName) {
      id
      languageCode
      languageName
      speechLanguageCode
    }
  }
`;
exports.GET_ALL_LANGUAGE_SERIES_CONFIGS = (0, graphql_tag_1.default) `
  query GetSeriesConfigs($seriesId: Int!) {
    seriesConfigs(seriesId: $seriesId) {
      id
      language
      showDisclaimerDialog
      pitch {
        id
        language
        name
        slide1text
        slide2text
        slide3text
        slide4text
      }
      welcome {
        id
        language
        name
        header
        subheader
      }
      identify {
        id
        language
        name
        header
        question1Text
        question1Answer
        question2Text
        question2Answer
      }
      thanks {
        id
        language
        name
        header
        title
        canCollectPhone
        subtitle
        footer
        showSelfInvite
      }
      videos {
        ...InterventionVideo
      }
      surveys {
        ...Survey
      }
      eConsent {
        id
        language
        name
        consent
        video {
          ...InterventionVideo
        }
      }
      demographicSurvey {
        ...Survey
      }
      engagementSurvey {
        ...Survey
      }
      prioritizedVideosToCache {
        ...InterventionVideo
      }
      reconcileReasonChoices
      optedOutReasonChoices
      didNotOfferReasonChoices
      facilitatorFaq
    }
  }
  ${fragments_1.surveyFragment}
  ${fragments_1.videoFragment}
`;
exports.GET_PARENT_ROLES = (0, graphql_tag_1.default) `
  query GetParentRoles {
    getParentRoles {
      id
      code
      name
    }
  }
`;
exports.GET_SURVEY_BY_TYPE_WITH_CONFIG_ID = (0, graphql_tag_1.default) `
  query GetSurveyByTypeWithConfigId($surveyType: String!, $language: String!,$surveyConfigId: String!) {
    surveyByTypeWithConfigId(surveyType: $surveyType, language: $language, surveyConfigId: $surveyConfigId) {
      ...Survey
    }
  }
  ${fragments_1.surveyFragment}
`;
exports.GET_ECE_COURSE_ID = (0, graphql_tag_1.default) `
  query GetEceCourseId {
    getEceCourseId
  }
`;
exports.GET_USER_COURSE_DETAIL = (0, graphql_tag_1.default) `
  query GetUserCourseDetail {
    getUserCourseDetail {
      certificateUrl
      fileName
    }
  }
`;
