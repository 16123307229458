"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatformApiLink = void 0;
const client_1 = require("@apollo/client");
const AuthLocalStorageKey = 'TMW.Authorization';
const readAuthHeaderFromResponse = (storage) => (operation, forward) => forward(operation).map(response => {
    const { response: { headers }, } = operation.getContext();
    const authHeaderValue = headers.get('authorization');
    if (authHeaderValue) {
        storage.setItem(AuthLocalStorageKey, authHeaderValue);
    }
    else {
        storage.removeItem(AuthLocalStorageKey);
    }
    return response;
});
const addAuthHeaderToRequest = storage => (operation, forward) => {
    const authHeaderValue = storage.getItem(AuthLocalStorageKey);
    const context = operation.getContext();
    operation.setContext(Object.assign(Object.assign({}, context), { headers: Object.assign(Object.assign({}, context.headers), { Authorization: authHeaderValue }) }));
    return forward(operation);
};
const PlatformApiLink = ({ uri }) => {
    const httpLink = new client_1.HttpLink({ uri });
    const storage = storageAvailable('localStorage')
        ? window.localStorage
        : window.sessionStorage;
    return new client_1.ApolloLink(readAuthHeaderFromResponse(storage))
        .concat(new client_1.ApolloLink(addAuthHeaderToRequest(storage)))
        .concat(httpLink);
};
exports.PlatformApiLink = PlatformApiLink;
// cf https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const storageAvailable = (type) => {
    let storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return (e instanceof DOMException &&
            // everything except Firefox
            //The quota has been exceeded: error code 22
            //(e.code === 22 ||
            (e.name === 'QUOTA_EXCEEDED_ERR' ||
                // Firefox
                //e.code === 1014 || - //NG took it out 
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0);
    }
};
