import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import classnames from 'classnames'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { IHowToPageFields } from '../types/generated/contentful'
import colors from '../theme/colors'
import {
  MarkdownPageTitleSection,
  MarkdownPageBodySection,
} from './MarkdownTypography'

import FAQEntry from './FAQEntry'
import { boldFontFamily } from '../theme'
import Link from './Link'
import Button from './Button'
import QuestionsCallout from './QuestionsCallout'
import AssetDownloadButton from './AssetDownloadButton'
import CalloutSection from './CalloutSection'
import { makeStyles } from '@mui/styles'
import { Container, Grid, Typography } from '@mui/material'

const useStyles = makeStyles(({ breakpoints }) => ({
  scheduleCallButton: {
    minWidth: 344,
    padding: '20px 8px',
  },
  heroText: {
    color: colors.white.primary,
    paddingTop: 25,
    paddingBottom: 25,
  },
  centeredText: {
    textAlign: 'center',
  },
  parentTitle: {
    marginBottom: 24,
  },
  printButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  printButton: {
    marginTop: 64,
    marginBottom: 24,
    padding: '15px 20px',
    [breakpoints.down('xs')]: {
      marginTop: 24,
    },
  },
  resourcesText: {
    marginTop: 40,
    marginBottom: 40,
  },
  section: {
    marginBottom: 64,
  },
  text: {
    marginTop: 24,
  },
  title: {
    fontFamily: boldFontFamily,
    marginTop: 64,
  },
}))

const HowTo: FC<RouteComponentProps> = () => {
  const classes = useStyles()

  const entry = useContentfulEntry<IHowToPageFields>('636Gqh1tChg4atRzi36RXU')

  if (!entry) return null

  const {
    fields: {
      introductorySectionTitle,
      introductorySectionBody,
      frameworkSectionTitle,
      frameworkImage,
      calloutTitle,
      emailButtonText,
      emailButtonPath,
      resources,
      resourcesText,
      scheduleCallButtonText,
      scheduleCallButtonUrl,
      stepByStepGuideTitle,
      stepByStepGuideBody,
      stepByStepGuideEntries,
      printButtonText,
      printVersion,
      calloutSections,
    },
  } = entry

  return (
    <Container>
      <Grid>
        <Grid xs={12} item>
          <MarkdownPageTitleSection source={introductorySectionTitle} />
          <MarkdownPageBodySection source={introductorySectionBody} />
        </Grid>
        <Grid container item xs={12}>
          {frameworkSectionTitle && (
            <Grid item xs={12}>
              <MarkdownPageTitleSection source={frameworkSectionTitle} />
              <Typography
                className={classnames(classes.title, classes.parentTitle)}
                color="primary"
                variant="h4"
                component="h2"
              >
                {frameworkSectionTitle}
              </Typography>
            </Grid>
          )}
          {frameworkImage && (
            <Grid item xs={12}>
              <img
                src={frameworkImage.fields.file.url}
                alt={frameworkImage.fields.description}
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid xs={12} sm={8} item>
            <MarkdownPageBodySection
              source={stepByStepGuideTitle}
              className={classnames(classes.title, classes.parentTitle)}
            />
          </Grid>
          <Grid sm={4} xs={12} item className={classes.printButtonContainer}>
            <Link href={printVersion.fields.file.url} underline="none">
              <Button
                color="primary"
                variant="contained"
                component="button"
                className={classes.printButton}
              >
                {printButtonText}
              </Button>
            </Link>
          </Grid>
          <MarkdownPageBodySection source={stepByStepGuideBody} />
        </Grid>
        <Grid container>
          {stepByStepGuideEntries.map(item => {
            return <FAQEntry item={item} key={item.sys.id} />
          })}
        </Grid>
        <Grid xs={12} item className={classes.resourcesText}>
          <MarkdownPageBodySection source={resourcesText} />
        </Grid>
        <Grid container spacing={3} wrap="wrap">
          {resources &&
            resources.map(asset => (
              <AssetDownloadButton key={asset.sys.id} asset={asset} />
            ))}
        </Grid>
        <QuestionsCallout
          calloutText={calloutTitle}
          emailButtonPath={emailButtonPath}
          emailButtonText={emailButtonText}
          scheduleCallButtonText={scheduleCallButtonText}
          scheduleCallButtonUrl={scheduleCallButtonUrl}
        />
        <Grid container spacing={3}>
          {calloutSections &&
            calloutSections.map(callout => (
              <CalloutSection
                key={callout.sys.id}
                entry={callout}
                itemSize={4}
              />
            ))}
        </Grid>
      </Grid>
    </Container>
  )
}

export default HowTo
