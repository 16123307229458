import React from 'react';
export var applyCapitalization = function (data) {
    return typeof data === 'string'
        ? data.toUpperCase().replace('3TS', '3Ts').replace('3CS', '3Cs')
        : data;
};
var CapitalizationTransformer = function (_a) {
    var children = _a.children;
    var transformedChildren = React.Children.toArray(children).map(function (child) {
        return applyCapitalization(child);
    });
    return React.createElement(React.Fragment, null, transformedChildren);
};
export default CapitalizationTransformer;
