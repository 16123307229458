import client from './client'
import { Entry, Asset, EntryCollection } from 'contentful'
import { useEffect, useState } from 'react'

function useContentfulEntry<T>(
  entryId: string,
  options: Record<string, any> = {}
): Entry<T> | null {
  const [entry, setEntry] = useState<Entry<T> | null>(null)

  useEffect(() => {
    const getEntry = async () => {
      const fetched = await client.getEntry<T>(entryId, options)
      setEntry(fetched)
    }

    getEntry()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryId])

  return entry
}

export function useContentfulEntries<T>(): EntryCollection<T> | null {
  const [entries, setEntries] = useState<EntryCollection<T> | null>(null)

  useEffect(() => {
    const getEntries = async () => {
      const fetched = await client.getEntries<T>({
        content_type: 'landingPage',
      })
      setEntries(fetched)
    }

    getEntries()
  }, [])

  return entries
}

export function useContentfulAsset(
  assetId: string,
  options: Record<string, any> = {}
): Asset | null {
  const [asset, setAsset] = useState<Asset | null>(null)

  useEffect(() => {
    const getAsset = async () => {
      const fetched = await client.getAsset(assetId, options)
      setAsset(fetched)
    }

    getAsset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId])

  return asset
}

export default useContentfulEntry
