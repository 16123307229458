"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTypedMutation = exports.useLazyTypedQuery = exports.useTypedQuery = exports.useLazyQuery = exports.useQuery = exports.useMutation = exports.setupHooks = void 0;
const notYetSetup = () => {
    throw new Error('tmw-api-client: need to call setupHooks()');
};
let hooks = {
    useMutation: notYetSetup,
    useQuery: notYetSetup,
    useLazyQuery: notYetSetup,
};
/**
 * Set the useMutation and useQuery hooks
 */
const setupHooks = (apolloHooks) => {
    hooks = apolloHooks;
};
exports.setupHooks = setupHooks;
const useMutation = (q, o) => hooks.useMutation(q, o);
exports.useMutation = useMutation;
const useQuery = (q, o) => hooks.useQuery(q, o);
exports.useQuery = useQuery;
const useLazyQuery = (q, o) => hooks.useLazyQuery(q, o);
exports.useLazyQuery = useLazyQuery;
const useTypedQuery = (query, options) => (0, exports.useQuery)(query.document, options);
exports.useTypedQuery = useTypedQuery;
const useLazyTypedQuery = (query, options) => (0, exports.useLazyQuery)(query.document, options);
exports.useLazyTypedQuery = useLazyTypedQuery;
const useTypedMutation = (mutation, options) => (0, exports.useMutation)(mutation.document, options);
exports.useTypedMutation = useTypedMutation;
