import { CircularProgress } from '@mui/material'
import React, { FC } from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
})

const Loading: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <CircularProgress size={80} color="primary" />
    </div>
  )
}

export default Loading
