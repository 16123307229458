import React from 'react'
import './i18n'
import App from './App'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import { setupHooks } from '@tmw/api-client'
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'
setupHooks({
  useMutation,
  useQuery,
  useLazyQuery,
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
