import { string } from 'yup'
import { TFunction } from 'i18next'

export const zipCodeValidator = (t: TFunction) =>
  string().matches(/^[0-9]{5}$/, t<string>('validations.zipCode.invalid'))

export const stateValidator = (t: TFunction) =>
  string().length(2, t<string>('validations.state.invalid'))

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/

export const passwordValidator = (t: TFunction) =>
  string()
    .min(8, t<string>('validations.password.length'))
    .matches(PASSWORD_REGEX, t<string>('validations.password.strength'))

export const translateErrorMessage = (
  t: TFunction,
  path: string,
  message: string | undefined
) => {
  if (message) {
    const messageArray = message.split(' ')
    if (messageArray.length === 1) {
      return t(`${path}.${message}`)
    }
  }
  return message
}
