import React, { ReactNode } from 'react'
import PasswordCheckIcon from '../assets/password-check.svg'
import colors from '../theme/colors'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles({
  checkImg: {
    margin: '0 10px 0 0',
  },
  rulesList: {
    listStyle: 'none',
    padding: '0',
    textAlign: 'left',
    margin: 0,
    lineHeight: 0,
  },
  rulesContent: {
    color: colors.black.primary,
  },
})

interface PasswordRuleProps {
  children: String
}

export const PasswordRule: React.FC<PasswordRuleProps> = ({ children }) => {
  const classes = useStyles()
  return (
    <li>
      <Typography
        component="span"
        variant="body2"
        className={classes.rulesContent}
      >
        <img src={PasswordCheckIcon} alt="" className={classes.checkImg} />
        {children}
      </Typography>
    </li>
  )
}

interface PasswordRulesProps {
  children: ReactNode[]
}

export const PasswordRules: React.FC<PasswordRulesProps> = ({ children }) => {
  const classes = useStyles()
  return <ul className={classes.rulesList}>{children}</ul>
}
