"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_PASSWORD = exports.PARTNER_ME = exports.SIGN_UP_MUTATION = exports.REQUEST_VERIFICATION_CODE = exports.RESET_PASSWORD_WITH_VERIFICATION_CODE_MUTATION = exports.VALIDATE_VERIFICATION_CODE_MUTATION = exports.VERIFY_USERNAME_MUTATION = exports.GET_CURRENT_USER = exports.LOG_OUT = exports.LOG_IN_WITH_TOKEN = exports.LOG_IN = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragments_1 = require("./fragments");
exports.LOG_IN = (0, graphql_tag_1.default) `
  mutation LogIn(
    $username: String
    $email: String
    $password: String!
    $role: String!
  ) {
    logIn(
      username: $username
      email: $email
      password: $password
      role: $role
    ) {
      ...UserInfo
    }
  }
  ${fragments_1.userInfoFragment}
`;
exports.LOG_IN_WITH_TOKEN = (0, graphql_tag_1.default) `
  mutation LogInWithToken($input: LogInWithTokenInputType!) {
    logInWithToken(input: $input) {
      ...UserInfo
      parentAppProgress
      parent {
        ...Parent
      }
    }
  }
  ${fragments_1.userInfoFragment}
  ${fragments_1.parentFragment}
`;
exports.LOG_OUT = (0, graphql_tag_1.default) `
  mutation LogOut {
    logout
  }
`;
exports.GET_CURRENT_USER = (0, graphql_tag_1.default) `
  query GetCurrentUser {
    currentUser {
      ...UserInfo
    }
  }
  ${fragments_1.userInfoFragment}
`;
exports.VERIFY_USERNAME_MUTATION = (0, graphql_tag_1.default) `
  mutation VerifyUsername($username: String!) {
    verifyUsername(username: $username) {
      status
    }
  }
`;
exports.VALIDATE_VERIFICATION_CODE_MUTATION = (0, graphql_tag_1.default) `
  mutation ValidateVerificationCode(
    $username: String!
    $verificationCode: String!
  ) {
    validateVerificationCode(
      username: $username
      verificationCode: $verificationCode
    ) {
      status
    }
  }
`;
exports.RESET_PASSWORD_WITH_VERIFICATION_CODE_MUTATION = (0, graphql_tag_1.default) `
  mutation ResetPasswordWithVerificationCode(
    $username: String!
    $verificationCode: String!
    $password: String!
  ) {
    resetPasswordWithVerificationCode(
      input: {
        username: $username
        verificationCode: $verificationCode
        password: $password
      }
    ) {
      ...UserInfo
    }
  }
  ${fragments_1.userInfoFragment}
`;
exports.REQUEST_VERIFICATION_CODE = (0, graphql_tag_1.default) `
  mutation RequestVerificationCode($username: String!) {
    requestVerificationCode(username: $username) {
      status
    }
  }
`;
exports.SIGN_UP_MUTATION = (0, graphql_tag_1.default) `
  mutation SignUpParent(
    $username: String!
    $password: String!
    $tmwId: String
    $postalCode: String
    $referralCode: String
    $referrerId: String
    $language: String!
  ) {
    signUpParent(
      username: $username
      password: $password
      tmwId: $tmwId
      postalCode: $postalCode
      referralCode: $referralCode
      referrerId: $referrerId
      language: $language
    ) {
      createdAt
      id
      email
      phoneNumber
      parentAppProgress
      eceProgress
      eceStatus
      parent {
        allowNotifications
        childGender
        hasCompletedAnyInterventions
        surveyStatuses {
          surveyState
          surveyType
        }
        id
        language
        mustCompletePostSpeakSurvey
        tmwId
      }
      userRole {
        isSetUpPending
        role {
          id
          code
          name
        }
      }
    }
  }
`;
exports.PARTNER_ME = (0, graphql_tag_1.default) `
  query PartnerMe {
    currentUser {
      id
      role
      userRole {
        roleId
        isSetUpPending
        role {
          code
        }
      }
    }
  }
`;
exports.CHANGE_PASSWORD = (0, graphql_tag_1.default) `
  mutation ChangePassword($changePasswordInput: ChangePasswordInputType!) {
    changePassword(changePasswordInput: $changePasswordInput) {
      ...UserInfo
      parent {
        ...Parent
      }
      parentAppProgress
      eceProgress
      eceStatus
    }
  }

  ${fragments_1.userInfoFragment}
  ${fragments_1.parentFragment}
`;
