import React, { FC, useState } from 'react'
import {
  AppBar as MUIAppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Container,
  Theme,
  Drawer,
  Button,
} from '@mui/material'

import { navigate, RouteComponentProps, Location } from '@reach/router'
import { find, get } from 'lodash'
import colors from '../theme/colors'
import useContentfulEntry from '../contentful/useContentfulEntry'
import { IHeadersAndFootersFields } from '../types/generated/contentful'
import { headersAndFootersId } from '../contentful/ids'
import {
  resourcesPath,
  logoutPath,
  rootPath,
  learnMorePath,
  howToPath,
} from '../paths'
import { makeStyles } from '@mui/styles'
import { Menu as MenuIcon } from '@mui/icons-material'

export const appBarHeight = 100

const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
  bar: {
    backgroundColor: colors.white.primary,
    height: appBarHeight,
  },
  innerContainer: {
    height: '100%',
  },
  menu: {
    color: colors.black.medium,
    display: 'block',
    marginRight: 24,
    visibility: 'visible',
    [breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  logoImage: {
    maxWidth: '140px',
  },
  menuIcon: {
    width: 32,
    height: 'auto',
  },
  tab: {
    '&.Mui-selected': {
      color: colors.blue.primary,
    },
  },
  tabs: {
    display: 'none',
    visibility: 'hidden',
    [breakpoints.up('md')]: {
      display: 'block',
      visibility: 'visible',
    },
  },
  verticalTabs: {
    borderRight: `1px solid ${palette.divider}`,
  },
  toolBar: {
    height: '100%',
  },
  siteTitle: {
    color: colors.purple.primary,
    display: 'flex',
    marginLeft: 24,
    marginRight: 44,
  },
}))

const tabs = [
  { route: rootPath, value: 'home' },
  { route: learnMorePath, value: 'learnMore' },
  { route: howToPath, value: 'get-started' },
  { route: resourcesPath, value: 'collateral' },
  { route: logoutPath, value: 'logout' },
]

const tabFromPathname = (pathname: string) =>
  get(find(tabs, { route: pathname }), 'value', false)

export const navigateToPageByValue = (value: string) => {
  // @ts-ignore
  navigate(find(tabs, { value: value }).route)
}

interface AppBarProps extends RouteComponentProps {
  elevation?: number
}

const AppBar: FC<AppBarProps> = ({ elevation = 0, location }) => {
  const classes = useStyles()
  const entry =
    useContentfulEntry<IHeadersAndFootersFields>(headersAndFootersId)

  const pathname = location?.pathname || window.location.pathname

  const currentTab = tabFromPathname(pathname)

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  if (!entry) return null

  const {
    fields: { siteLogo, siteTitle, navBarPageTitles },
  } = entry

  const updateCurrentTab = (_: any, newValue: string) => {
    // @ts-ignore
    navigateToPageByValue(newValue)
    setTimeout(() => setDrawerOpen(false), 600)
  }

  return (
    <>
      <MUIAppBar elevation={elevation} className={classes.bar}>
        <Container className={classes.innerContainer}>
          <Toolbar disableGutters className={classes.toolBar}>
            <Button
              className={classes.menu}
              onClick={() => {
                setDrawerOpen(!drawerOpen)
              }}
            >
              <MenuIcon className={classes.menuIcon} />
            </Button>
            <img
              aria-hidden="true"
              alt=""
              src={siteLogo.fields.file.url}
              className={classes.logoImage}
            />
            <Typography
              variant="h5"
              component="span"
              className={classes.siteTitle}
            >
              {siteTitle}
            </Typography>
            <Tabs
              value={currentTab}
              onChange={updateCurrentTab}
              className={classes.tabs}
              indicatorColor="primary"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={navBarPageTitles[index]}
                  label={navBarPageTitles[index]}
                  value={tab.value}
                  className={classes.tab}
                />
              ))}
            </Tabs>
          </Toolbar>
        </Container>
      </MUIAppBar>
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Tabs
          value={currentTab}
          onChange={updateCurrentTab}
          className={classes.verticalTabs}
          orientation="vertical"
          indicatorColor="primary"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={navBarPageTitles[index]}
              label={navBarPageTitles[index]}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Drawer>
    </>
  )
}

const AppBarWithLocation = (props: AppBarProps) => (
  <Location>
    {({ location }) => <AppBar location={location} {...props} />}
  </Location>
)

export default AppBarWithLocation
