import React, { FC, ReactNode } from 'react'
import { RouteComponentProps } from '@reach/router'
import cx from 'classnames'
import AppBar from './AppBar'
import NoNavAppBar from './NoNavAppBar'
import Footer from './Footer'
import colors from '../theme/colors'
import { useCurrentUser } from '../context/currentUser'
import { makeStyles } from '@mui/styles'
import { useScrollTrigger } from '@mui/material'

type Props = {
  children: ReactNode
}

interface PageWrapperProps extends RouteComponentProps, Props {
  className?: string
}

const useStyles = makeStyles({
  container: {
    backgroundColor: colors.white.primary,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  pageContent: {
    flex: '1 0',
    paddingTop: 100,
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
    },
  },
  contentWrapper: {
    display: 'flex',
    flex: '1 0 100vh',
    flexDirection: 'column',
  },
})

const PageWrapper: FC<PageWrapperProps> = ({ className, children }) => {
  const { isSignedIn } = useCurrentUser()
  const classes = useStyles()
  const elevateAppBar = useScrollTrigger({
    disableHysteresis: true,
  })

  return (
    <div className={cx([classes.container, className])}>
      {isSignedIn ? (
        <>
          <AppBar elevation={elevateAppBar ? 4 : 0} />
          <div className={classes.contentWrapper}>
            <div className={classes.pageContent}>{children}</div>
            <Footer />
          </div>
        </>
      ) : (
        <>
          <NoNavAppBar elevation={elevateAppBar ? 4 : 0} />
          <div className={cx(classes.pageContent, classes.contentWrapper)}>
            {children}
          </div>
        </>
      )}
    </div>
  )
}

export default PageWrapper
