import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import useContentfulEntry from '../../contentful/useContentfulEntry'
import { IAmbassadorsPageFields } from '../../types/generated/contentful'
import MarkdownTypography, {
  MarkdownPageBodySection,
  MarkdownPageTitleSection,
} from '../MarkdownTypography'
import FAQEntry from '../FAQEntry'
import colors from '../../theme/colors'
import { boldFontFamily } from '../../theme'
import { ambassadorsId } from '../../contentful/ids'
import { makeStyles } from '@mui/styles'
import { Container, Grid, Theme } from '@mui/material'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  appCallout: {
    backgroundColor: colors.purple.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 64,
    marginTop: 64,
    padding: 64,
  },
  appCalloutText: {
    color: colors.white.primary,
    marginBottom: 64,
    textAlign: 'center',
  },
  bottomLink: {
    marginTop: 24,
    textAlign: 'center',
  },
  button: {
    minWidth: 344,
    padding: '20px 8px',
  },
  container: {
    paddingBottom: 88,
    [breakpoints.up('md')]: {
      padding: '0 88px 88px',
    },
  },
  title: {
    fontFamily: boldFontFamily,
    marginTop: 64,
    marginBottom: 24,
  },
}))

const Page: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const entry = useContentfulEntry<IAmbassadorsPageFields>(ambassadorsId)

  if (!entry) return null
  const {
    fields: {
      title,
      body,
      trainingStepsSectionTitle,
      trainingSteps,
      staffTrainingPageLink,
    },
  } = entry

  return (
    <Container className={classes.container}>
      <MarkdownPageTitleSection source={title} />
      <MarkdownPageBodySection source={body} />
      {trainingStepsSectionTitle && (
        <MarkdownPageTitleSection source={trainingStepsSectionTitle} />
      )}
      <Grid container>
        {trainingSteps?.map(item => {
          return <FAQEntry item={item} key={item.sys.id} />
        })}
      </Grid>
      {staffTrainingPageLink && (
        <MarkdownTypography
          className={classes.bottomLink}
          source={staffTrainingPageLink}
        />
      )}
    </Container>
  )
}

export default Page
